export const categories = [
  {
    name: 'Anywhere',
    color: 'sky-500',
  },
  {
    name: 'Arctic',
    color: 'sky-500',
  },
  {
    name: 'Coastal',
    color: 'sky-500',
  },
  {
    name: 'Desert',
    color: 'sky-500',
  },
  {
    name: 'Forest',
    color: 'sky-500',
  },
  {
    name: 'Lake',
    color: 'sky-500',
  },
  {
    name: 'Underwater',
    color: 'sky-500',
  },
  {
    name: 'River',
    color: 'sky-500',
  },
  {
    name: 'Sky',
    color: 'sky-500',
  },
  {
    name: 'Swamp',
    color: 'sky-500',
  },
  {
    name: 'Plain',
    color: 'sky-500',
  },
  {
    name: 'Mountain',
    color: 'sky-500',
  },
  {
    name: 'Town',
    color: 'amber-600',
  },
  {
    name: 'City',
    color: 'amber-600',
  },
  {
    name: 'Comedy',
    color: 'rose-600',
  },
  {
    name: 'Crime',
    color: 'rose-600',
  },
  {
    name: 'Mystery',
    color: 'rose-600',
  },
  {
    name: 'Horror',
    color: 'rose-600',
  },
  {
    name: 'Romance',
    color: 'rose-600',
  },
  {
    name: 'Science Fiction',
    color: 'rose-600',
  },
  {
    name: 'Steampunk',
    color: 'rose-600',
  },
  {
    name: 'Tragedy',
    color: 'rose-600',
  },
  {
    name: 'Ad (Fictional)',
    color: 'purple-500',
  },
  {
    name: 'Anonymous',
    color: 'purple-500',
  },
  {
    name: 'Battle and Guard',
    color: 'purple-500',
  },
  {
    name: 'Bounty',
    color: 'purple-500',
  },
  {
    name: 'Event',
    color: 'purple-500',
  },
  {
    name: 'Exploration',
    color: 'purple-500',
  },
  {
    name: 'Job',
    color: 'purple-500',
  },
  {
    name: 'Missing Person',
    color: 'purple-500',
  },
  {
    name: 'Politics',
    color: 'purple-500',
  },
  {
    name: 'Religion',
    color: 'purple-500',
  },
  {
    name: 'Social',
    color: 'purple-500',
  },
  {
    name: 'Warning',
    color: 'purple-500',
  },
  {
    name: 'Web Sponsors',
    color: 'purple-500',
  },
  {
    name: 'Award Winning',
    color: 'fuchsia-600',
  },
  {
    name: 'Poetry',
    color: 'fuchsia-600',
  },
  {
    name: "Hidden Messages",
    color: 'fuchsia-600',
  },
  {
    name: 'Reusable',
    color: 'fuchsia-600',
  },
  {
    name: 'Wallflower',
    color: 'fuchsia-600',
  },
  {
    name: 'Encounter',
    color: 'indigo-500',
  },
  {
    name: 'Dungeon',
    color: 'indigo-500',
  },
  {
    name: 'Adventure & One Shot',
    color: 'indigo-500',
  },
  {
    name: 'Campaign',
    color: 'indigo-500',
  },
  {
    name: 'Paid',
    color: 'indigo-500',
  },
  {
    name: 'Free',
    color: 'indigo-500',
  },
  {
    name: 'One Shot',
  },
  {
    name: 'CR 1-5',
    color: 'emerald-500',
  },
  {
    name: 'CR 6-10',
    color: 'emerald-500',
  },
  {
    name: 'CR 11-15',
    color: 'emerald-500',
  },
  {
    name: 'CR 16-20',
    color: 'emerald-500',
  },
  {
    name: 'CR 21-30',
    color: 'emerald-500',
  },
  {
    name: 'TPK',
    color: 'emerald-500',
  },
  {
    name: 'Originality',
  },
  {
    name: 'Original Maps',
    color: 'green-300',
  },
  {
    name: 'Original Enemies',
    color: 'green-300',
  },
  {
    name: 'Original Art',
    color: 'green-300',
  },
  {
    name: 'Tabletop',
  },
  {
    name: 'Owlbear Rodeo',
    color: 'pink-400',
  },
  {
    name: 'Printable Material',
    color: 'pink-400',
  },
  {
    name: 'Roll20',
    color: 'pink-400',
  },
  {
    name: 'Other',
    color: 'pink-400',
  },
  {
    name: 'Flexible Reward',
    color: 'yellow-400',
  },
  {
    name: '1-100 Gold',
    color: 'yellow-400',
  },
  {
    name: '100-1000 Gold',
    color: 'yellow-400',
  },
  {
    name: '1000-10000 Gold',
    color: 'yellow-400',
  },
  {
    name: '10000-50000 Gold',
    color: 'yellow-400',
  },
  {
    name: '50000-Infinite Gold',
    color: 'yellow-400',
  },
  {
    name: 'something',
  },
  {
    name: 'Competitions',
    color: 'cyan-400',
  },
  {
    name: 'Best Adventure',
    color: 'cyan-400',
  },
  {
    name: 'Best Encounter',
    color: 'cyan-400',
  },
  {
    name: 'Best Poetry',
    color: 'cyan-400',
  },
  {
    name: 'Best Hidden Messages',
    color: 'cyan-400',
  },
  {
    name: 'Most Witty',
    color: 'cyan-400',
  },
];

const orderBy = sessionStorage.getItem("order")
const seen = localStorage.getItem("seen")
if (seen) {if (orderBy === 'rank') {var order = 'order(_createdAt desc) | order(rank desc)'} else {order = 'order(_createdAt desc)'}} else {
  if (orderBy === 'created') {order = 'order(_createdAt desc)'} else {order = 'order(_createdAt desc) | order(rank desc)'}
}

export const feedQuery = `*[_type == "pin"] | ${order} {
  image{
    asset->{
      url
    }
  },
  thieves{
    asset->{
      url
    }
  },
      _id,
      destination,
      social,
      postedBy->{
        _id,
        userName,
        image
      },
      save[]{
        _key,
        postedBy->{
          _id,
          userName,
          image
        },
      },
    } `;

export const pinDetailQuery = (pinId) => {
  const query = `*[_type == "pin" && _id == '${pinId}']{
    image{
      asset->{
        url
      }
    },
    thieves{
      asset->{
        url
      }
    },
    _id,
    title, 
    about,
    category,
    destination,
    social,
    postedBy->{
      _id,
      userName,
      image
    },
   save[]{
      _key,
      postedBy->{
        _id,
        userName,
        image
      },
      userId,
    },
    comments[]{
      comment,
      _key,
      postedBy->{
        _id,
        userName,
        image
      },
    }
  }`;
  return query;
};

export const searchQuery = (searchTerm) => {
  const query = `*[_type == "pin" && title match '${searchTerm}*' || category match '${searchTerm}*' || about match '${searchTerm}*'] | ${order} {
        image{
          asset->{
            url
          }
        },
        thieves{
          asset->{
            url
          }
        },
            _id,
            destination,
            social,
            postedBy->{
              _id,
              userName,
              image
            },
            save[]{
              _key,
              postedBy->{
                _id,
                userName,
                image
              },
            },
          }`;
  return query;
};

export const userQuery = (userId) => {
  const query = `*[_type == "user" && _id == '${userId}']`;
  return query;
};

export const userCreatedPinsQuery = (userId) => {
  const query = `*[ _type == 'pin' && userID == '${userId}'] | ${order} {
    image{
      asset->{
        url
      }
    },
    thieves{
      asset->{
        url
      }
    },
    _id,
    destination,
    social,
    postedBy->{
      _id,
      userName,
      image
    },
    save[]{
      postedBy->{
        _id,
        userName,
        image
      },
    },
  }`;
  return query;
};

export const userSavedPinsQuery = (userId) => {
  const query = `*[_type == 'pin' && '${userId}' in save[].userId ] | ${order} {
    image{
      asset->{
        url
      }
    },
    thieves{
      asset->{
        url
      }
    },
    _id,
    destination,
    social,
    postedBy->{
      _id,
      userName,
      image
    },
    save[]{
      postedBy->{
        _id,
        userName,
        image
      },
    },
  }`;
  return query;
};

export const userUnSavedPinsQuery = (userId, id) => {
  const query = `*[_type == 'pin' && _id == '${id}' && '${userId}' != save[].postedBy._id ] {
    save[]{
      _key,
      postedBy->{
        _id,
        userName,
        image
      },
      userId,
    },
  }`;
  return query;
};
