import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Eye from '../assets/Eye.png';
import Anonymous from '../assets/Anonymous.png';
import './Sidebar.css'
import Owlbear from '../assets/Owlbear.png'
import Owlbear2 from '../assets/Owlbear2.png'
import {  useLocation } from 'react-router-dom';

import { categories } from '../utils/data';

import Login from './Login.jsx';

const Sidebarphone = ({ user, closeToggle}) => {
  
  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };

  const loc = useLocation();
  const location = loc.pathname

  function thieves() {
    const screen = document.getElementsByName("backdrop");
    for (const backdrop of screen) {
      if (backdrop.classList.contains("grayscale")) {
        backdrop.classList.remove("grayscale")
      } else {backdrop.classList.add("grayscale")}
      if (backdrop.classList.contains("brightness-50")) {
        backdrop.classList.remove("brightness-50")
      } else {backdrop.classList.add("brightness-50")}
      if (backdrop.classList.contains("pointer-events-none")) {
        backdrop.classList.remove("pointer-events-none")
      } else {backdrop.classList.add("pointer-events-none")}
    };
    const cant = document.getElementsByName("thieves");
    for (const thieves of cant) {
      if (thieves.classList.contains("invisible")) {
        thieves.classList.remove("invisible")
      } else {thieves.classList.add("invisible")}
      };
    const bamber = document.getElementById("amber");
      if (bamber.classList.contains("wood")) {
        bamber.classList.remove("wood")
      } else {bamber.classList.add("wood")}
      if (bamber.classList.contains("graywood")) {
        bamber.classList.remove("graywood")
      } else {bamber.classList.add("graywood")};
    const menu = document.getElementById('himenu');
      if (menu.classList.contains('bg-pink-500')) {
        menu.classList.remove('bg-pink-500')
      } else {menu.classList.add('bg-pink-500')}
      if (menu.classList.contains('rounded-full')) {
        menu.classList.remove('rounded-full')
      } else {menu.classList.add('rounded-full')}
    const campfire = document.getElementById("campfire");
      if (campfire.classList.contains("sepia")) {
        campfire.classList.remove("sepia")
      } else {campfire.classList.add("sepia")}
  };

  function dethief() {
    const bear = document.getElementById('bear'); if (bear.classList.contains('grayscale')) {thieves()}
    handleCloseSidebar()
  }

  function dropdown() {
    document.getElementById("myDropdown1").classList.toggle("show");
  }

  function dropdown2() {
    document.getElementById("Dropdown22").classList.toggle("show");
  }
  function dropdown3() {
    document.getElementById("Dropdown33").classList.toggle("show");
  }
  function dropdown4() {
    document.getElementById("Dropdown44").classList.toggle("show");
  }
  function dropdown5() {
    document.getElementById("Dropdown55").classList.toggle("show");
  }
  function dropdown6() {
    document.getElementById("Dropdown66").classList.toggle("show");
  }
  function dropdown7() {
    document.getElementById("Dropdown77").classList.toggle("show");
  }
  function dropdown8() {
    document.getElementById("Dropdown88").classList.toggle("show");
  }
  function dropdown9() {
    document.getElementById("Dropdown99").classList.toggle("show");
  }
  function dropdown10() {
    document.getElementById("Dropdown100").classList.toggle("show");
  }
  function dropdown11() {
    document.getElementById("Dropdown110").classList.toggle("show");
  }
  function dropdown12() {
    document.getElementById("Dropdown120").classList.toggle("show");
  }

  return (
    <div className="flex flex-col scale-125 mt-28 bg-black shadow-lg h-full overflow-y-scroll min-w-210 hide-scrollbar hider">
      <div className="flex flex-col">
      <Link
            to="/"
            className="justify-center flex items-center px-5 pap-3 transition-all duration-500 ease-in-out"
            onClick={dethief}
          >
            <img className="relative" name="backdrop" src={Owlbear} alt="Owlbear Home" width="190" />
            <img className="absolute invisible" name="thieves" id="bringbear" src={Owlbear2} alt="Owlbear Angry" width="190" />
          </Link>
    <div className="flex flex-row space-x-4 p-1 mt-1 justify-center items-center">
    <div name="backdrop" className="flex-col relative">
          <a href="https://www.patreon.com/umbraaevaz" target="_blank" rel="noreferrer" className="justify-center flex items-center text-sm rainbow opacity-90">PATREON</a>
          <a href="https://discord.gg/YDv2NMpDRr" target="_blank" rel="noreferrer" className="justify-center flex items-center text-md text-indigo-500 transition-all duration-200 ease-in-out">DISCORD</a>
          <a href="https://www.youtube.com/channel/UCrNPVorkc3vX_19Y9cexl3Q/featured" target="_blank" rel="noreferrer" className="justify-center text-sm flex items-center home text-rose-600 text-bold transition-all duration-200 ease-in-out">YOUTUBE</a>
          </div>
      <button onClick={thieves} className=" transition-all duration-700 ease-in-out"><img src={Eye} width="64" height="66.17872" alt="Eye"/></button>
      <a href="/starter-page" name="backdrop" className="hover:scale-125 transition-all duration-700 ease-in-out"><img src={Anonymous} width="64" height="64" alt="Anonymous"/></a>
    </div>
    <p name="backdrop" className="text-purple-500 mx-3 text-sm justify-center items-center flex">-To use the Board, use a larger screen.</p>
    <div name="backdrop">
    <div className="dropdown justify-center flex flex-col items-center">
  <button onClick={dropdown} className="justify-center flex items-center px-5 pap-3 text-red-600 hover:text-red-500 underline underline-offset-1 dropbtn 2xl:text-2xl">CATEGORIES</button>
  <div id="myDropdown1" className="dropdowncontent 2xl:text-2xl 2xl:space-y-2">
          <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown2} className="dropbtn justify-center items-center text-sky-400">Setting</button>
    <div id="Dropdown22" className="dropdowncontent">
      {categories.slice(0, categories.length -63).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="leading-5 flex flex-col justify-center items-center text-gray-50 hover:text-sky-500 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown3} className="dropbtn justify-center items-center text-amber-500">Urban</button>
    <div id="Dropdown33" className="dropdowncontent">
      {categories.slice(12, categories.length -61).map((category) => (
            <NavLink
            to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-amber-600 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown5} className="dropbtn justify-center items-center text-rose-500">Genre</button>
    <div id="Dropdown55" className="dropdowncontent">
      {categories.slice(14, categories.length -53).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-rose-600 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown4} className="dropbtn justify-center items-center text-purple-400">Theme</button>
    <div id="Dropdown44" className="dropdowncontent">
      {categories.slice(22, categories.length -40).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-purple-500 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown6} className="dropbtn justify-center items-center text-fuchsia-500">Special</button>
    <div id="Dropdown66" className="dropdowncontent">
      {categories.slice(35, categories.length -35).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-fuchsia-600 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown7} className="dropbtn justify-center items-center text-indigo-400">Homebrew</button>
    <div id="Dropdown77" className="dropdowncontent">
      {categories.slice(40, categories.length -29).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-indigo-500 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown12} className="dropbtn justify-center items-center text-emerald-400">Challenge</button>
    <div id="Dropdown120" className="dropdowncontent">
      {categories.slice(47, categories.length -22).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-emerald-500 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown8} className="dropbtn justify-center items-center text-green-200">Originality</button>
    <div id="Dropdown88" className="dropdowncontent">
      {categories.slice(54, categories.length -18).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-green-300 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown9} className="dropbtn justify-center items-center text-pink-300">Tabletop</button>
    <div id="Dropdown99" className="dropdowncontent">
      {categories.slice(58, categories.length -13).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-pink-400 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown10} className="dropbtn justify-center items-center text-yellow-500">Reward</button>
    <div id="Dropdown100" className="dropdowncontent">
      {categories.slice(62, categories.length -7).map((category) => (
            <NavLink
              to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-yellow-400 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown11} className="dropbtn justify-center items-center text-cyan-300">Competitions</button>
    <div id="Dropdown110" className="dropdowncontent">
      {categories.slice(70, categories.length -0).map((category) => (
            <NavLink
             to={`${location}+${category.name}`}
              key={category.name}
            >
              <div className="flex flex-col justify-center items-center text-gray-50 hover:text-cyan-400 transition-all duration-200">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      </div>
<a href="https://homebrewery.naturalcrit.com/share/QTzI8YAEuVaA" target="_blank" rel="noreferrer" className="justify-center items-center flex duration-200 ease-in-out"><img src="https://cdn.sanity.io/images/xjnviay9/production/618d8d7da193138266d750e065db4e1ea9a8e709-2112x2268.png?w=600" alt="The Future" className="justify-center items-center flex h-60"/></a>
<div className="relative">
      <div className="mx-5 2xl:scale-150 2xl:ml-28 z-10">
            <Login/>
           </div>
           </div>
           <p className="text-fuchsia-500 home mx-5 mt-1 flex justify-center items-center">Developed by Umbra Aevaz</p>
      </div>
      </div>
      </div>
      </div>
  );
};

export default Sidebarphone;
