import React, { useState } from 'react';
import Localbase from 'localbase'
import Reload from '../assets/Reload.png'
import Missing from '../assets/Missing.PNG'

const Cp = ({ user }) => {
  const [pastes, setPastes] = useState('');
  const [bpastes, setBpastes] = useState('');
  const [fpastes, setFpastes] = useState('');
  const [reset, setReset] = useState(false);
  const [copied, setCopied] = useState(false);
  const [pasted, setPasted] = useState(false);

  if (!user) return <div className='rounded-full p-2 bg-red-500 text-xl'>
 Please Sign In
  </div>;

  let Boards = new Localbase('Boards')

  function close() {
    const cp = document.getElementById('cp')
    cp.classList.remove('flex');
    cp.classList.add('hidden');
  };

  function copy() {
    var copyText = document.getElementById('copy');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000)
  }

  function copyb() {
    var copyText = document.getElementById('copyback');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000)
  }

  function copyf() {
    var copyText = document.getElementById('copyfore');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000)
  }

  function paste() {
    const paste = document.getElementById('paste');
    const value = paste.getAttribute('value');
    [value].forEach(e => {
     const array = JSON.parse(e)
     for (const pin of array) {
     const pawn = pin.pinid
     Boards.collection('Board').doc({id: `${pawn}`}).update({pin})}
    });
    setPasted(true);
    setTimeout(() => setPasted(false), 2000)
  };

  function pasteforeground() {
    const paste = document.getElementById('pasteforeground');
    const value = paste.getAttribute('value');
    Boards.collection('Grounds').doc({id: 'foreground'}).update({link: `${value}`});
    setPasted(true);
    setTimeout(() => setPasted(false), 2000)
  }

  function pastebackground() {
    const paste = document.getElementById('pastebackground');
    const value = paste.getAttribute('value');
    Boards.collection('Grounds').doc({id: 'background'}).update({link: `${value}`});
    setPasted(true);
    setTimeout(() => setPasted(false), 2000)
  }

  function Togglefore() {
    const fore = document.getElementById('foreground')
    const tog = document.getElementById('togglebutton')
    if (fore.classList.contains('invisible')) {
      fore.classList.remove('invisible');
      tog.innerHTML = 'Toggle Foreground: On'
    } else {fore.classList.add('invisible'); 
    tog.innerHTML = 'Toggle Foreground: Off'}
  };

  function Reset() {
    Boards.delete();
    localStorage.removeItem('Board');
    setReset(true);
  };

  return (
    <div className="w-full h-full justify-center items-center flex">
    {!user.subed && (
    <div className='relative flex flex-col justify-center items-center h-5/6 w-10/12 rounded-lg'>
    <img src={Missing} alt="Missing" className="rounded-lg w-full h-full blur-sm"/>
    <button onClick={close} className='absolute right-3 top-0 home bg-white bg-opacity-60 rounded-full px-3 text-4xl pin'>x</button>
    <div className=" absolute w-2/3 bg-white bg-opacity-60 text-red-800 p-2 rounded-lg px-4 text-xl">
    With this feature you can Copy Boards, Paste Boards, Paste Backgrounds, and Paste Foregrounds. Essentially allowing you to save, share, and upload infinite fully compiled boards. Collections of which can be found in <a href="https://discord.gg/YDv2NMpDRr" target="_blank" rel="noreferrer" className="text-blue-600 underline underline-offset-1">The Owlbear Post Discord</a>. Unfortunately the two CPs on this website, the Copy/Paste and Create Post features, are only available to <a href='https://www.patreon.com/umbraaevaz' className="text-purple-600 underline underline-offset-1" target="_blank" rel="noreferrer">Umbra Aevaz Patreon</a> subscribers. <p className="text-xl font-bold underline underline-offset-1">**HOWEVER**</p>This feature will become available for free if the Patreon support pays for the website's servers, the developer's rent, and his healthy ramen and only ramen diet (the goal being 400 Patrons). If by some miracle the Patreon ever goes above 1200 Patrons, the developer, Umbra Aevaz, pledges to use 20% of his income as prize money for the website competitions.
    </div>
    </div>
    )}
    {user.subed && (
    <div className='relative flex flex-col justify-center items-center bg-amber-900 h-5/6 w-10/12 rounded-lg'>
        <button onClick={close} className='absolute right-3 top-0 home text-4xl pin'>x</button>
        <div className='flex flex-row mb-2 w-11/12'>
        <div className='flex flex-col w-56'>
        <p className='flex home font-bold text-l'>Saved Board</p>
        <p className='flex home font-bold text-l'>(JSON Format)</p>
        </div>
        <button onClick={copy} className='home rounded-full bg-red-500 text-l mx-3 p-3 font-bold pin'>
          Copy
        </button>
        <input type="text" id="copy" className='flex mx-3 w-full outline-none text-l sm:text-l shadow-md rounded-lg bg-amber-800 p-1'>
        </input>
        </div>
        <div className='flex flex-row w-11/12 mb-5 mt-2'>
        <div className='flex flex-col w-56'>
        <p className='flex home font-bold text-l'>Import Board</p>
        <p className='flex home font-bold text-l'>(JSON Format)</p>
        </div>
        <input
            type="text"
            id="paste"
            value={pastes}
            onChange={(e) => setPastes(e.target.value)}
            placeholder="Paste Board Here => Press Paste Button => Reload Page"
            className="flex mx-3 w-full outline-none text-l sm:text-l shadow-md rounded-lg bg-amber-800 border-gray-500 p-1"
          />
          <button onClick={paste} className='home rounded-full mx-3 bg-red-500 text-l pin font-bold p-3'>
          Paste
        </button>
        <button onClick={() => window.location.reload()} className="pin">
          <img src={Reload} alt="reload" width="64"/>
        </button>
          </div>
          <div className='flex flex-row mb-2 mt-5 w-11/12'>
        <div className='flex flex-col w-56'>
        <p className='flex home font-bold text-l'>Saved Background</p>
        <p className='flex home font-bold text-l'>(Link Format)</p>
        </div>
        <button onClick={copyb} className='home rounded-full bg-red-500 text-l mx-3 p-3 font-bold pin'>
          Copy
        </button>
        <input type="text" id="copyback" className='flex mx-3 w-full outline-none text-l sm:text-l shadow-md rounded-lg bg-amber-800 p-1'>
        </input>
        </div>
        <div className='flex flex-row w-11/12 mb-5 mt-2'>
        <div className='flex flex-col w-56'>
        <p className='flex home font-bold text-l'>Import Background</p>
        <p className='flex home font-bold text-l'>(Link Format)</p>
        </div>
        <input
            type="text"
            id="pastebackground"
            value={bpastes}
            onChange={(e) => setBpastes(e.target.value)}
            placeholder="Paste Background Here => Press Paste Button => Reload Page"
            className="flex mx-3 w-full outline-none text-l sm:text-l shadow-md rounded-lg bg-amber-800 border-gray-500 p-1"
          />
          <button onClick={pastebackground} className='home rounded-full mx-3 bg-red-500 text-l pin font-bold p-3'>
          Paste
        </button>
        <button onClick={() => window.location.reload()} className="pin">
          <img src={Reload} alt="reload" width="64"/>
        </button>
          </div>
          <div className='flex flex-row mb-2 mt-5 w-11/12'>
        <div className='flex flex-col w-56'>
        <p className='flex home font-bold text-l'>Saved Foreground</p>
        <p className='flex home font-bold text-l'>(Link Format)</p>
        </div>
        <button onClick={copyf} className='home rounded-full bg-red-500 text-l mx-3 p-3 font-bold pin'>
          Copy
        </button>
        <input type="text" id="copyfore" className='flex mx-3 w-full outline-none text-l sm:text-l shadow-md rounded-lg bg-amber-800 p-1'>
        </input>
        </div>
        <div className='flex flex-row w-11/12 mt-2'>
        <div className='flex flex-col w-56'>
        <p className='flex home font-bold text-l'>Import Foreground</p>
        <p className='flex home font-bold text-l'>(Link Format)</p>
        </div>
        <input
            type="text"
            id="pasteforeground"
            value={fpastes}
            onChange={(e) => setFpastes(e.target.value)}
            placeholder="Paste Foreground Here => Press Paste Button => Reload Page"
            className="flex mx-3 w-full outline-none text-l sm:text-l shadow-md rounded-lg bg-amber-800 border-gray-500 p-1"
          />
          <button onClick={pasteforeground} className='home rounded-full mx-3 bg-red-500 text-l pin font-bold p-3'>
          Paste
        </button>
        <button onClick={() => window.location.reload()} className="pin">
          <img src={Reload} alt="reload" width="64"/>
        </button>
          </div>
          <div className="flex flex-row bottom-0 absolute">
          <button id="togglebutton" className="bg-pink-500 p-1 font-bold pin rounded-t-lg mx-3" onClick={Togglefore}>Toggle Foreground: On</button>
          <button id="togglebutton" className="bg-pink-500 p-1 font-bold pin rounded-t-lg mx-3" onClick={Reset}>Reset Board to Defaults</button>
          </div>
      </div>
      )}
      {reset && (
        <p className="absolute flex justify-center items-center bg-red-500 p-3 rounded-full text-2xl mt-20 z-40">
          Please <button onClick={() => window.location.reload()} className=" mx-2 pin">
          <img src={Reload} alt="reload" width="32"/>
        </button>
        </p>
        )}
        {pasted && (
    <p className="absolute flex justify-center items-center bg-red-500 p-3 rounded-full text-2xl mt-20 z-40">
      Pasted
    </p>
    )}
    {copied && (
    <p className="absolute flex justify-center items-center bg-red-500 p-3 rounded-full text-2xl mt-20 z-40">
      Copied
    </p>
    )}
      </div>
  );
};

export default Cp;
