import React from 'react';
import './Sidebar.css'
import GenFeed from './GenFeed'

const NewSidebar = () => {

  function used() {
    const used = document.getElementById("used")
    if (used.classList.contains("hidden")) {
      used.classList.remove("hidden")
    } else {used.classList.add("hidden")};
  };

  return (
    <div id="scrollr" className="scrolled shadow-lg h-full p-1 bg-black overflow-y-scroll min-w-210 max-w-min z-20">
            <GenFeed/>
            <div id="editon" className="absolute home invisible right-0 text-center top-0 rounded-b-lg p-1 bg-rose-800 min-w-210 max-w-min font-bold">Edit Off</div>
            <button onClick={used} className="absolute home right-0 bottom-0 rounded-t-lg p-1 bg-pink-600 min-w-210 max-w-min font-bold">Mimic Bin</button>
           </div>
       );
      };
export default NewSidebar;
