import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: 'xjnviay9',
  dataset: 'production',
  apiVersion: '2022-01-16',
  ignoreBrowserTokenWarning: true,
  useCdn: false,
  token: 'skIBU9Z1sGrNxvFsiyN9IdPGM1VC3mpsxBuSPp9RcufeC2oSEGooPY35jXZKv6Um3ddZdYv40KEwdjrYUghn3awgPyRcL9nZLNswucaikjYb0eirXkNUw57CTVbQBjQyQ27MAGxI0kgIv6c2lWYwUv9l4vqCxmmekBpq6y3PA987yGU0rVAW',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
