import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import { MasonryLayout, Spinner } from '.';
import { client } from '../client';
import { feedQuery, searchQuery } from '../utils/data';
import './Sidebar.css'

const Search = ({ searchTerm }) => {
  const [pins, setPins] = useState(null);
  const [loading, setLoading] = useState(true);
    
  const { categoryId } = useParams();

    useEffect(() => {
        setLoading(true);

        if(categoryId) {
            const query = searchQuery(categoryId)

            client.fetch(query).then((data) => {
                setPins(data);
                setLoading(false);
            })
        } else {
            client.fetch(feedQuery).then((data) => {
                setPins(data);
                setLoading(false);
            })
        }

    }, [categoryId])

  useEffect(() => {
    if (searchTerm) {
      setLoading(true);

      const query = searchQuery(searchTerm.toLowerCase());
      client.fetch(query).then((data) => {
        setPins(data);
        setLoading(false);
      });
    } else {
      client.fetch(feedQuery).then((data) => {
        setPins(data);
        setLoading(false);
      });
    }
  }, [searchTerm]);

  if(loading) return <Spinner message="Rolling Investigation!" />
    
    if(!pins?.length) return <h2 className="flex items-center justify-center text-white home">Failed Investigation Check</h2>

  return (
    <div>
      {loading && <Spinner message="Rolling Investigation" />}
      {pins?.length !== 0 && <MasonryLayout pins={pins} />}
      {pins?.length === 0 && searchTerm !== '' && !loading && (
        <div className="mt-10 text-center text-xl text-white home home">Failed Investigation Check</div>
      )}
    </div>
  );
};

export default Search;
