import React from 'react';
import Campfire from '../assets/OwlbearCampfire.gif'
import top from '../assets/TOP.png'
import manual from '../assets/Manual.png'
import './Sidebar.css'

const StarterPage = () => {


function homebrewery() {
  window.open('https://homebrewery.naturalcrit.com/share/n6u_v__tSCtr');
  return true;
}

  return (
    <div id="starterpage" className="justify-center items-center bg-black -mb-2 shadow-2xl">
    <img src={top} name="backdrop" className="" alt="The Owlbear Post" />
    <div className="flex lg:flex-row flex-col">
    <div name="backdrop" className="flex-col">
    <div className="flex md:flex-row flex-col">
    <div className="text-amber-500 indent-6 2xl:text-xl">
      <p className="home text-xl text-center indent-0">༺What is TOP?༻</p>
<p>The Owlbear Post is intended to be a notice board creation tool, and a gallery for notices filled with inspiring ideas, encounters, adventures, one shots, hidden messages, and what have you, for the public's TTRPG games. The developer hopes to house a community of artists/creators, and to inspire any and all Dungeon Masters who use the site. Check out The Owlbear Post Manual should you need help figuring out any of the site's functionalities. You can also join the Discord, and become a part of the community:)</p>
    </div>
    <img src={manual} onClick={homebrewery} className="w-4/12 h-4/12 2xl:w-1/6 2xl:h-1/6 ml-3 mt-12 pin cursor-pointer" alt="Manual"/>
    </div>
    <p className="text-amber-500 indent-6 2xl:text-xl">Begin browsing notices through the Owlbear button at the top of the sidebar. By signing up with google you will be able to collect Inspirations, create boards with those Inspirations, comment on posts, and if you become a Patron: Create Posts & Copy/Paste Boards.</p><p className="text-amber-500 indent-6 font-bold"> ↡ Privacy Policy & Updates ↡</p>
    </div>
    <img src={Campfire} id="campfire" className="rounded-full lg:w-2/5 lg:h-2/5 w-full h-full" alt="Owlbear Campfire" />
    </div>
    <div name="backdrop" className="flex md:flex-row flex-col">
    <div className="bg-black text-rose-500 indent-6 md:w-5/12 md:mr-14 2xl:text-xl">
      <p className="home text-xl indent-12">༺Privacy Policy༻</p>
<p>When signing up on The Owlbear Post all that is taken is your email address, profile picture, and username. We hope you can trust us with this information. As long as you do not post or comment anything on The Owlbear Post, none of this information will be released to other parties besides the website developer.</p>
<p >Upon posting and/or commenting, your profile picture and username will become visible to other users. Under no circumstance will your email address be released to the public. The website developer only requires it to notify you under the circumstance that you have won a competition or your account is showing suspicious/guideline breaking activity.</p>
    </div>
    <div className="md:w-1/2 text-purple-400 2xl:text-xl">
    <p className="home text-xl indent-12">༺Updates༻</p>
    <p>Update 1.1: Categories are highlighted when pressed, and can be undone. Also fixed a scaling bug in the board. ⤜(⚆ᗨ⚆)⤏</p>
    </div>
    </div>
    <p name="backdrop" className="text-fuchsia-400 text-lg right-0 text-right mr-6 mt-1">©2022 Umbra Aevaz | All Rights Reserved</p>
    </div>
  );
};

export default StarterPage;
