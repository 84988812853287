import React from 'react';
import Masonry from 'react-masonry-css';
import Pin2 from './Pin2';

const breakpointObj = {
    default: 1,
    3000: 1,
    2000: 1,
    1200: 1,
    1000: 1,
    500: 1,
}

const MasonryLayout = ({ pins }) => {

    return (
        <Masonry className="animate-slide-fwd" breakpointCols={breakpointObj}>
            {pins?.map((pin) => <Pin2 key={pin._id} pin={pin} className="w-max" />)}
        </Masonry>
    )
}

export default MasonryLayout
