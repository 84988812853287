import React from 'react';
import './Sidebar.css'
import Mimic from '../assets/Mimic.png'
import Mimictop from '../assets/Mimictop.png'

const Used = () => {

  function used() {
    const used = document.getElementById("used")
    if (used.classList.contains("hidden")) {
      used.classList.remove("hidden")
    } else {used.classList.add("hidden")};
  };

  return (
    <div id="used" className="hidden z-20">
          <div id="drophere" className="h-screen p-1 bg-pink-900 home min-w-210 max-w-min overflow-y-scroll hide-scrollbar hider justify-center items-center">
           </div>
           <img src={Mimic} alt="MimicBottom" className="absolute right-0 bottom-8"  />
           <div className="absolute right-0 bottom-0 home p-1 bg-pink-800 min-w-210 font-bold">hi</div>
           <button onClick={used} className="absolute home right-0 bottom-0 rounded-t-lg p-1 bg-black text-pink-500 min-w-210 max-w-min font-bold">Inspirations</button>
           <img src={Mimictop} alt="MimicTop" className="absolute right-0 top-0"  />
           <div id="truth" className="invisible">
           </div>
    </div>
       )
      }
      
export default Used;
