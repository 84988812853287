import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import './Sidebar.css'
import Clock from '../assets/Clock.png'
import Inspiration from '../assets/Inspiration.png'

const Navbar = ({ searchTerm, setSearchTerm, user, setNote }) => {
  const navigate = useNavigate();
  
  const [ranked, setRanked] = useState(false);
  const ordering = sessionStorage.getItem("order"); 
  const seen = localStorage.getItem("seen")
  function order() {if (seen) {if (ordering === 'rank') {sessionStorage.setItem("order", 'created')
  } else {sessionStorage.setItem("order", 'rank')}; window.location.reload()} else {
    if (ordering === 'created') {sessionStorage.setItem("order", 'rank')} else {sessionStorage.setItem("order", 'created')}; window.location.reload()
  }};

  function ranker() {
    const scene = sessionStorage.getItem("seen"); 
    if (scene) {sessionStorage.removeItem("seen"); window.location.reload();};
    if (seen) {if (ordering === 'rank') {setRanked(true)};
    } else {if (ordering === 'created') {setRanked(false)} else {setRanked(true)}}};

  if (!user) return (<div name="backdrop" onLoad={ranker} className="flex gap-2 md:gap-5 bg-transparent w-full mt-5 pb-7">
  <div className="flex justify-start items-center w-full px-2 rounded-md bg-black border-none outline-none focus-within:shadow-sm">
    <input
      type="text"
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search"
      value={searchTerm}
      onFocus={() => navigate('search')}
      className="p-2 w-full bg-gray-100 rounded-md outline-none"
    />
  </div>
  <button onClick={order} className="relative text-white rounded-lg shadow-lg w-12 h-12 flex justify-center items-center">
          {ranked ? (
          <div>
               <img src={Inspiration} title="Order by Creation" alt="Ranked" className="absolute bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-20" />
            <img src={Clock} alt="Clock" className="bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-10" />
          </div>
          ) : (
          <div>
            <img src={Clock} title="Order by Inspiration Dice" alt="Clock" className="absolute bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-20" />
            <img src={Inspiration} alt="Ranked" className="bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-10" />
          </div>
          )}
        </button>
  </div>);

  return (
    <div name="backdrop" onLoad={ranker} className="flex gap-2 md:gap-5 bg-transparent w-full mt-5 pb-7">
      <div className="flex justify-start items-center w-full px-2 rounded-md bg-black border-none outline-none focus-within:shadow-sm">
        <input
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search"
          value={searchTerm}
          onFocus={() => navigate('search')}
          className="p-2 w-full bg-gray-100 rounded-md outline-none"
        />
      </div>
      <div className="flex space-x-3">
        <button onClick={order} className="relative text-white rounded-lg shadow-lg w-12 h-12 flex justify-center items-center">
          {ranked ? (
          <div>
               <img src={Inspiration} title="Order by Creation" alt="Ranked" className="absolute bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-20" />
            <img src={Clock} alt="Clock" className="bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-10" />
          </div>
          ) : (
          <div>
            <img src={Clock} title="Order by Inspiration Dice" alt="Clock" className="absolute bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-20" />
            <img src={Inspiration} alt="Ranked" className="bg-black rounded-lg pin hover:opacity-0 transition-all duration-500 ease-out z-10" />
          </div>
          )}
        </button>
        <Link 
          to={`/user-profile/${user?._id}`} className="hidden md:block w-12 h-12">
            <img 
              src={user.image}
              alt="user" 
              className="rounded-lg shadow-lg pin bg-black flex"/>
        </Link>
        {user.subed && (
        <a href="/create-post" className="bg-black text-white rounded-lg shadow-lg w-12 h-12 flex justify-center items-center hover:bg-amber-800 transition-all duration-700 ease-in-out">
            <IoMdAdd />
        </a>     
        )}   
        {user && !user.subed && (
        <button onClick={(e) => setNote(true)} className="bg-black text-white rounded-lg shadow-lg w-12 h-12 flex justify-center items-center hover:bg-amber-800 transition-all duration-700 ease-in-out">
            <IoMdAdd />
        </button>     
        )}   
      </div>
    </div>
  );
};

export default Navbar;
