import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Sidebar.css'
import Clock from '../assets/Clock.png'
import Inspiration from '../assets/Inspiration.png'

import {
  userCreatedPinsQuery,
  userQuery,
  userSavedPinsQuery,
} from '../utils/data';
import { client } from '../client';
import MasonryLayout from './MasonryLayout';
import Spinner from './Spinner';

const activeBtnStyles =
  'bg-purple-500 mx-2 text-yellow-500 home font-bold p-2 rounded-full w-30 outline-none';
const notActiveBtnStyles =
  'bg-black mx-2 text-white home font-bold p-2 rounded-full w-30 outline-none';

const UserProfile = () => {
  const [user, setUser] = useState();
  const [pins, setPins] = useState();
  const [text, setText] = useState('Creations');
  const [activeBtn, setActiveBtn] = useState('Creations');
  const { userId } = useParams();
  const [ranked, setRanked] = useState(false)

  const seen = localStorage.getItem("seen");
  const ordering = sessionStorage.getItem("order"); 

  function order() {if (ordering === 'rank') {sessionStorage.setItem("order", 'created')
  } else {sessionStorage.setItem("order", 'rank')}; window.location.reload()}

  function rankerer() {if (ordering === 'rank') {setRanked(true)}};

  useEffect(() => {
    const query = userQuery(userId);
    client.fetch(query).then((data) => {
      setUser(data[0]);
    });
  }, [userId]);

  useEffect(() => {
    if (text === 'Creations') {
      const createdPinsQuery = userCreatedPinsQuery(userId);

      client.fetch(createdPinsQuery).then((data) => {
        setPins(data);
      });
    } else {
      const savedPinsQuery = userSavedPinsQuery(userId);

      client.fetch(savedPinsQuery).then((data) => {
        setPins(data);
      });
    }
  }, [text, userId]);

  if (!user) return <Spinner message="Loading profile" />;

  return (
    <div onLoad={rankerer} className="relative pb-2 h-full justify-center items-center">
      <div className="flex flex-col pb-5">
        <div className="relative flex flex-col mb-7">
          <div className="flex flex-col justify-center items-center">
            <img
              className="rounded-full w-20 h-20 mt-10  shadow-xl object-cover"
              src={user.image}
              alt="user-pic"
            />
          </div>
          <h1 className="font-bold text-3xl text-center mt-3 home text-rose-100">
            {user.userName}
          </h1>
        </div>
        <div className="items-center w-full place-content-center mb-7 flex flex-row">
          <button
            type="button"
            onClick={(e) => {
              setText(e.target.textContent);
              setActiveBtn('Creations');
            }}
            className={`${
              activeBtn === 'Creations' ? activeBtnStyles : notActiveBtnStyles
            }`}
          >
            Creations
          </button>
          <button onClick={order} className="relative text-white rounded-full shadow-lg w-8 h-8 md:w-10 md:h-10 flex justify-center items-center">
          {ranked ? (
          <div>
               <img src={Inspiration} title="Order by Creation" alt="Ranked" className="absolute bg-black rounded-full pin hover:opacity-0 transition-all duration-500 ease-out z-20" />
            <img src={Clock} alt="Clock" className="bg-black rounded-full pin hover:opacity-0 transition-all duration-500 ease-out z-10" />
          </div>
          ) : (
          <div>
            <img src={Clock} title="Order by Rank" alt="Clock" className="absolute bg-black rounded-full pin hover:opacity-0 transition-all duration-500 ease-out z-20" />
            <img src={Inspiration} alt="Ranked" className="bg-black rounded-full pin hover:opacity-0 transition-all duration-500 ease-out z-10" />
          </div>
          )}
        </button>
          <button
            type="button"
            onClick={(e) => {
              setText(e.target.textContent);
              setActiveBtn('Inspirations');
            }}
            className={`${
              activeBtn === 'Inspirations' ? activeBtnStyles : notActiveBtnStyles
            }`}
          >
            Inspirations
          </button>
        </div>

        <div className="px-2">
          <MasonryLayout pins={pins} />
        </div>

        {pins?.length === 0 && (
          <div className="flex justify-center font-bold items-center w-full text-1xl mt-2 text-white home">
            Failed History Check
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
