export function allowance (ev) {
    ev.target.classList.add("pointer-events-none")
  };

export function disallowance () {
  const window = document.getElementById("membrane")
    window.classList.remove("pointer-events-none");
  const background = document.getElementById("background")
    background.classList.remove("pointer-events-none");
  const posts = document.getElementsByName("rnd")
    for (const rnd of posts) 
      {rnd.classList.remove("pointer-events-none")};
  const pins = document.getElementsByName("pin")
    for (const pin of pins) {
      if (pin.classList.contains("off")) {
        pin.classList.remove("pointer-events-none")}
    };
}