import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './container/Home';
import Generator from './container/Generator';
import Redirect from './container/Redirect'

const App = () => {

  return (
    <Routes>
      <Route path="/Board/:userId" element={<Generator />} />
      <Route path="/*" element={<Home />} />
      <Route path="/maintenance" element={<Redirect />} />
    </Routes>
  );
};

export default App;
