import React from 'react';
import { useNavigate } from 'react-router-dom';
import {disallowance} from './allowance.js'
import {allowance} from './allowance.js'
import LazyLoad from 'react-lazy-load';
import './Sidebar.css'


import { urlFor } from '../client';

const Pin = ({ pin: { image, _id, thieves } }) => {
  const navigate = useNavigate();

      function drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
      }

      var id = "id" + Math.random().toString(16).slice(2)

      function loaded(ev) {
        ev.target.parentElement.parentElement.classList.remove('h-96')
        ev.target.classList.remove('invisible')
      }

  return (
    <div className="m-2">
      <LazyLoad offsetVertical={500} className="h-96">
      <div id={id} draggable="true"
        onDragStart={drag} 
        onDragEnd={disallowance}
        onDragEnter={allowance}
        name="pin"
        onClick={() => navigate(window.open(`/post/${_id}`))}
        target="_blank"
        rel="noreferrer"
        className="relative visible cursor-pointer overflow-hidden flex justify-start items-center off"
      >
        <img
          className="invisible now relative w-full justify-center"
          alt="user-post"
          name="backdrop"
          onLoad={loaded}
          id={`${_id}`}
          draggable="false"
          src={urlFor(image).width(1000).url()}
        />
        {thieves && (
        <img
          className="absolute top-0 bottom-0 justify-center invisible w-full"
          alt="user-post"
          name="thieves"
          draggable="false"
          src={urlFor(thieves).width(1000).url()}
        />)}
      </div>
      </LazyLoad>
    </div>
  );
};

export default Pin;
