import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Board from '../assets/Board.png'
import Eye from '../assets/Eye.png';
import Anonymous from '../assets/Anonymous.png';
import './Sidebar.css'
import Owlbear from '../assets/Owlbear.png'
import Owlbear2 from '../assets/Owlbear2.png'
import {  useLocation, useNavigate } from 'react-router-dom';

import { categories } from '../utils/data';
import Login from './Login.jsx';
import Arrow from '../assets/Arrow.mov'

const Sidebar = ({ user, closeToggle}) => {
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  const loc = useLocation();
  const location = loc.pathname
  const words = location.split('+')

  useEffect(() => {
    categories.forEach(e => {const coord = e.name.replaceAll(' ', '%20'); const using = !!words.filter((word) => word === coord)?.length; if (using) {const cate = document.getElementById(`${e.name}`);
    cate.classList.add('font-bold');
    cate.classList.add(`bg-rose-100`);
    cate.classList.add(`shadow-black`);
    cate.classList.replace('text-gray-50',`text-black`);
    cate.classList.remove(`hover:text-${e.color}`);
    } else {
    const cate = document.getElementById(`${e.name}`);
    if (cate !== null && cate.classList.contains('font-bold')) {
    cate.classList.remove('font-bold');
    cate.classList.replace(`text-black`,'text-gray-50');
    cate.classList.remove(`shadow-black`);
    cate.classList.remove(`bg-rose-100`);
    cate.classList.add(`hover:text-${e.color}`);
    }}})});

  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };

  function thieves() {
    const screen = document.getElementsByName("backdrop");
    for (const backdrop of screen) {
      if (backdrop.classList.contains("grayscale")) {
        backdrop.classList.remove("grayscale")
      } else {backdrop.classList.add("grayscale")}
      if (backdrop.classList.contains("brightness-50")) {
        backdrop.classList.remove("brightness-50")
      } else {backdrop.classList.add("brightness-50")}
      if (backdrop.classList.contains("pointer-events-none")) {
        backdrop.classList.remove("pointer-events-none")
      } else {backdrop.classList.add("pointer-events-none")}
    };
    const cant = document.getElementsByName("thieves");
    for (const thieves of cant) {
      if (thieves.classList.contains("invisible")) {
        thieves.classList.remove("invisible")
      } else {thieves.classList.add("invisible")}
      };
    const bamber = document.getElementById("amber");
      if (bamber.classList.contains("wood")) {
        bamber.classList.remove("wood")
      } else {bamber.classList.add("wood")}
      if (bamber.classList.contains("graywood")) {
        bamber.classList.remove("graywood")
      } else {bamber.classList.add("graywood")};
    const campfire = document.getElementById("campfire");
        if (campfire.classList.contains("sepia")) {
          campfire.classList.remove("sepia")
        } else {campfire.classList.add("sepia")}
  };

  function dethief() {
    const bear = document.getElementById('bear'); if (bear.classList.contains('grayscale')) {thieves()}
    handleCloseSidebar()
  }

  function dropdown() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  function dropdown2() {
    document.getElementById("Dropdown2").classList.toggle("show");
  }
  function dropdown3() {
    document.getElementById("Dropdown3").classList.toggle("show");
  }
  function dropdown4() {
    document.getElementById("Dropdown4").classList.toggle("show");
  }
  function dropdown5() {
    document.getElementById("Dropdown5").classList.toggle("show");
  }
  function dropdown6() {
    document.getElementById("Dropdown6").classList.toggle("show");
  }
  function dropdown7() {
    document.getElementById("Dropdown7").classList.toggle("show");
  }
  function dropdown8() {
    document.getElementById("Dropdown8").classList.toggle("show");
  }
  function dropdown9() {
    document.getElementById("Dropdown9").classList.toggle("show");
  }
  function dropdown10() {
    document.getElementById("Dropdown10").classList.toggle("show");
  }
  function dropdown11() {
    document.getElementById("Dropdown11").classList.toggle("show");
  }
  function dropdown12() {
    document.getElementById("Dropdown12").classList.toggle("show");
  }
  
  function sign(ev) {
    const sign = ev.target
    sign.classList.add('pinger')
    setTimeout(function () {sign.classList.add('pointer-events-none')}, 20)
    setTimeout(function () {sign.classList.add('invisible')}, 400);
    const begin = document.getElementById('begin');
    const begintwo = document.getElementById('begintwo');
    begin.classList.add('hidden');
    begintwo.classList.add('hidden');
  }

  function unbegin() {
    const begin = document.getElementById('begin');
    const begintwo = document.getElementById('begintwo');
    begin.classList.add('hidden');
    begintwo.classList.add('hidden');
  }

  function resign() {
    const sign = document.getElementById('signup')
    sign.classList.remove('pinger')
    sign.classList.remove('pointer-events-none')
    sign.classList.remove('invisible')
  }

  function onload() {
  const begin = document.getElementById('begin');
  const sign = document.getElementById('signstart') 
  setTimeout(function () {begin.classList.remove('hidden'); 
  setTimeout(function () {begin.classList.add('hidden'); 
  sign.classList.add('bubble'); setTimeout(function () {
  sign.classList.remove('bubble')}, 3000)}, 3300);}, 2500);}

  return (
    <div className="flex flex-col bg-black shadow-lg h-full overflow-y-scroll min-w-210 2xl:min-w-350 hide-scrollbar hider">
      {!user && (
      <div id="begin" className="hidden fixed top-6 mt-44 ml-16 left-3 w-24 z-30 pointer-events-none 2xl:top-14 2xl:ml-28 2xl:left-5 2xl:mt-64 2xl:w-36"><video src={Arrow} type="video/mov" controls={false} muted autoPlay></video></div>
      )}
      <div onLoad={onload} className="flex flex-col">
        <div className="2xl:scale-150 2xl:m-7">
      <Link
            to="/"
            className="justify-center flex items-center px-5 pap-3 mt-3 hover:scale-125 transition-all duration-500 ease-in-out"
            onClick={dethief}
          >
            <img id="bear" className="relative" name="backdrop" src={Owlbear} alt="Owlbear Home" width="190" />
            <img className="absolute invisible" name="thieves" src={Owlbear2} alt="Owlbear Angry" width="190" />
          </Link>
          </div>
    <div className="flex flex-row space-x-4 p-3 justify-center items-center 2xl:scale-150 2xl:space-x-2 2xl:p-7">
      <a href={`/Board/${user?._id}`} name="backdrop" className="hover:scale-125 transition-all duration-700 ease-in-out"><img src={Board} width="64" height="67.12528" alt="Board"/></a>
      <button onClick={thieves} title="Hidden Messages" id="thief" className="hover:scale-125 transition-all duration-700 ease-in-out"><img src={Eye} width="64" height="66.17872" alt="Eye"/></button>
      <Link to="/starter-page" name="backdrop" className="hover:scale-125 transition-all duration-700 ease-in-out"><img src={Anonymous} width="64" height="64" alt="Anonymous"/></Link>
    </div>
    <div name="backdrop" className="flex flex-col relative">
          <a href="https://www.patreon.com/umbraaevaz" target="_blank" rel="noreferrer" className="absolute justify-center flex items-center px-4 text-lg mx-16 rainbow opacity-90 2xl:text-3xl 2xl:mx-28">PATREON</a>
          <div className="flex flex-row mt-7 2xl:mt-10 2xl:mb-2">
          <a href="https://discord.gg/YDv2NMpDRr" target="_blank" rel="noreferrer" className="justify-center flex items-center px-7 underline underline-offset-0 pap-3 text-pink-500 home hover:text-indigo-500 hover:scale-125 transition-all duration-200 ease-in-out 2xl:text-2xl 2xl:px-14">DISCORD</a>
          <a href="https://www.youtube.com/channel/UCrNPVorkc3vX_19Y9cexl3Q/featured" target="_blank" rel="noreferrer" className="justify-center flex items-center pap-3 text-rose-500 underline underline-offset-0 home hover:text-rose-600 text-bold hover:scale-125 transition-all duration-200 rounded-lg p-1 ease-in-out 2xl:text-2xl">YOUTUBE</a>
          </div>
          <div className="dropdown justify-center flex flex-col items-center">
  <button onClick={dropdown} className="justify-center flex items-center px-5 pap-3 text-red-600 hover:text-red-500 underline underline-offset-1 dropbtn 2xl:text-2xl">CATEGORIES</button>
  <div id="myDropdown" className="dropdowncontent 2xl:text-2xl 2xl:space-y-2">
          <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown2} className="dropbtn justify-center items-center text-sky-400">Setting</button>
    <div id="Dropdown2" className="dropdowncontent">
      {categories.slice(0, categories.length -63).map((category) => (
              <NavLink
              to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-sky-500 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown3} className="dropbtn justify-center items-center text-amber-500">Urban</button>
    <div id="Dropdown3" className="dropdowncontent">
      {categories.slice(12, categories.length -61).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-amber-600 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown5} className="dropbtn justify-center items-center text-rose-500">Genre</button>
    <div id="Dropdown5" className="dropdowncontent">
      {categories.slice(14, categories.length -53).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-rose-600 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown4} className="dropbtn justify-center items-center text-purple-400">Theme</button>
    <div id="Dropdown4" className="dropdowncontent">
      {categories.slice(22, categories.length -40).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-purple-500 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown6} className="dropbtn justify-center items-center text-fuchsia-500">Special</button>
    <div id="Dropdown6" className="dropdowncontent">
      {categories.slice(35, categories.length -35).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-fuchsia-600 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown7} className="dropbtn justify-center items-center text-indigo-400">Homebrew</button>
    <div id="Dropdown7" className="dropdowncontent">
      {categories.slice(40, categories.length -29).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-indigo-500 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown12} className="dropbtn justify-center items-center text-emerald-400">Challenge</button>
    <div id="Dropdown12" className="dropdowncontent">
      {categories.slice(47, categories.length -22).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-emerald-500 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown8} className="dropbtn justify-center items-center text-green-200">Originality</button>
    <div id="Dropdown8" className="dropdowncontent">
      {categories.slice(54, categories.length -18).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-green-300 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown9} className="dropbtn justify-center items-center text-pink-300">Tabletop</button>
    <div id="Dropdown9" className="dropdowncontent">
      {categories.slice(58, categories.length -13).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-pink-400 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown10} className="dropbtn justify-center items-center text-yellow-500">Reward</button>
    <div id="Dropdown10" className="dropdowncontent">
      {categories.slice(62, categories.length -7).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-yellow-400 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      <div className="flex flex-col justify-center items-center dropdown">
    <button onClick={dropdown11} className="dropbtn justify-center items-center text-cyan-300">Competitions</button>
    <div id="Dropdown11" className="dropdowncontent">
      {categories.slice(70, categories.length -0).map((category) => (
            <NavLink
            to={!!words.filter((word) => word === category.name.replaceAll(' ', '%20'))?.length ? (`${words.filter((word) => word !== category.name.replaceAll(' ', '%20'))}`.replaceAll(',', '+')) : (`${location}+${category.name}`.replace('starter-page', ''))}
              key={category.name}
            >
              <div id={category.name} className="flex flex-col justify-center items-center text-gray-50 hover:text-cyan-400 transition-all duration-200 rounded-full px-1 shadow-inner ">{category.name}</div>
            </NavLink>
          ))}
          </div>
      </div>
      </div>
</div>
    <a href="https://homebrewery.naturalcrit.com/share/QTzI8YAEuVaA" target="_blank" rel="noreferrer" className="justify-center items-center flex pin duration-200 ease-in-out my-2">
      <img src="https://cdn.sanity.io/images/xjnviay9/production/618d8d7da193138266d750e065db4e1ea9a8e709-2112x2268.png?w=600" alt="The Future" onMouseEnter={unbegin} className="justify-center 2xl:h-420 2xl:m-2 items-center flex h-52"/>
      {!user && (
      <div id="begintwo" className="absolute text-xl w-full fade h-full 2xl:-mt-3 -mt-2 z-20 pointer-events-none"></div>
      )}
      </a>
      <div className="relative">
      <div id="signup" onMouseEnter={sign} className="bg-black text-rose-700 absolute z-40 p-1 mx-5 2xl:text-4xl 2xl:p-2 2xl:border-4 2xl:-mt-3 2xl:mx-9 text-xl cursor-pointer rounded-full text-center w-5/6 home border-2 border-rose-800">
        Sign Up Here: _____
      </div>
      {!user && (
        <div id="signstart" onMouseEnter={sign} className="bg-black text-rose-700 absolute z-40 p-1 mx-5 2xl:text-4xl 2xl:p-2 2xl:border-4 2xl:-mt-3 2xl:mx-9 text-xl cursor-pointer rounded-full text-center w-5/6 home border-2 border-rose-800">
        Sign Up Here: _____
      </div>
      )}
      <div id="google" onMouseLeave={resign} className="z-10 mx-6 left-0.5 2xl:scale-150 2xl:ml-28">
            <Login/>
           </div>
           </div>
           <p className="text-fuchsia-500 home mx-6 mt-2 2xl:text-2xl 2xl:mt-5 2xl:mx-12">Developed by Umbra Aevaz</p>
      </div>
      <div id="reset" className="pointer-events-none bg-transparent"></div>
      </div>
    </div>
  );
};

export default Sidebar;
