import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { userQuery } from '../utils/data';
import { client } from '../client';
import { NewSidebar } from '../components';
import Gear from '../assets/Gear.png';
import GalleryButton from '../assets/Gallery Button.png';
import Save from '../assets/Save.png';
import Compass from '../assets/Compass.png'
import Copper from '../assets/Copper.png'
import Inspiration from '../assets/Inspirational.png'
import Eye from '../assets/Eye.png';
import '../components/Sidebar.css';
import Board from '../components/Board'
import Used from '../components/Used'
import Localbase from 'localbase'
import Cp from '../components/Cp.jsx'
import 'react-rnd'

const Generator = () => {  
  
  const [save, setSave] = useState(false);
  const [thieveson, setThievesOn] = useState(false);
  const [user, setUser] = useState();

  let Boards = new Localbase('Boards')

  const userInfo =
    localStorage.getItem('user') !== 'undefined'
      ? JSON.parse(localStorage.getItem('user'))
      : localStorage.clear();


      function add() {
        const c = document.getElementById("NewSidebar");
          if (c.classList.contains("-right-96")) {
            c.classList.remove("-right-96")
          } else {c.classList.add("-right-96")}
          if (c.classList.contains("right-0")) {
            c.classList.remove("right-0")
          } else {c.classList.add("right-0")}
        var d = document.getElementById("otherused");
        if (d.classList.contains("-right-96")) {
          d.classList.remove("-right-96")
        } else {d.classList.add("-right-96")};
        if (d.classList.contains("right-0")) {
          d.classList.remove("right-0")
        } else {d.classList.add("right-0")}
      };

      function edit() {
        const editon = document.getElementById('editon')
        const pin1 = document.getElementById('pin1')
        if (pin1.classList.contains('invisible')) {
          editon.classList.add('invisible')
        } else {editon.classList.remove('invisible')}
        const back = document.getElementsByName("rnd")
          for (const trans of back) {
            if (trans.classList.contains("invisible")) {
              trans.classList.remove("invisible")
            } else {trans.classList.add("invisible")}
            if (trans.classList.contains("cancel")) {
              trans.classList.remove("cancel")
            } else {trans.classList.add("cancel")}
          };
        const editable = document.getElementsByClassName("editable")
        for (const pointer of editable) {
          if (pointer.classList.contains("pointer-events-none")) {
            pointer.classList.remove("pointer-events-none")
          } else {pointer.classList.add("pointer-events-none")}; 
          if (pointer.classList.contains("off")) {
              pointer.classList.remove("off")
            } else {pointer.classList.add("off")}};      
      };

      function thieves() {
        const screen = document.getElementsByName("backdrop");
        for (const backdrop of screen) {
          if (backdrop.classList.contains("grayscale")) {
            backdrop.classList.remove("grayscale")
          } else {backdrop.classList.add("grayscale")}
          if (backdrop.classList.contains("brightness-50")) {
            backdrop.classList.remove("brightness-50")
          } else {backdrop.classList.add("brightness-50")}
        }
        const cant = document.getElementsByName("thieves");
        for (const thieves of cant) {
          if (thieves.classList.contains("invisible")) {
            thieves.classList.remove("invisible")
          } else {thieves.classList.add("invisible")}
          }
          setThievesOn(true);
          setTimeout(() => setThievesOn(false), 800);
      };

      function doSave() {
        const background = document.getElementById('background');
        const foreground = document.getElementById('foreground');
        var screen = window.screen.width
        Boards.collection('Grounds').doc({id: 'background'}).update({link: `${background.src}`});
        Boards.collection('Grounds').doc({id: 'foreground'}).update({link: `${foreground.src}`});
        var pins = document.getElementsByName('rnd');
        for (const pawn of pins) {
          const pinid = pawn.getAttribute('id');
          const pinclass = pawn.getAttribute('class');
          const pinstyle = pawn.getAttribute('style');
          if (pawn.children[2]) {
            const postid = pawn.children[2].getAttribute('id');
            const postclass = pawn.children[2].getAttribute('class');
            const picclass = pawn.children[2].children[0].getAttribute('class');
            const picid = pawn.children[2].children[0].getAttribute('id');
            const picsrc = pawn.children[2].children[0].getAttribute('src');
          if (!pawn.children[2].children[1]) {
            const pin = {pinid, pinclass, pinstyle, postid, postclass, picclass, picid, picsrc}
          Boards.collection('Board').doc({id: `${pawn.id}`}).update({pin});
          } else {
            const thiefclass = pawn.children[2].children[1].getAttribute('class');
            const thiefsrc = pawn.children[2].children[1].getAttribute('src');
            const pin = {pinid, pinclass, pinstyle, postid, postclass, picclass, picid, picsrc, thiefclass, thiefsrc}
          Boards.collection('Board').doc({id: `${pawn.id}`}).update({pin})};
          } else {
          const pin = {pinid, pinclass, pinstyle}
          Boards.collection('Board').doc({id: `${pawn.id}`}).update({pin})}};
          const pinid = 'pin13'
          const z = `${foreground.style.zIndex}`
          const dime = `${screen}`
          const pin = {pinid, z, dime}
          Boards.collection('Board').doc({id: 'pin13'}).update({pin});
        setSave(true);
        setTimeout(() => setSave(false), 2000);
      }

      function cp() {
        const cp = document.getElementById('cp')
        cp.classList.remove('hidden')
        cp.classList.add('flex')
        if (user.subed) {
        const copy = document.getElementById('copy')
        copy.removeAttribute('value')
        const copyfore = document.getElementById('copyfore')
        copyfore.removeAttribute('value')
        Boards.collection('Grounds').doc({id: 'foreground'}).get().then(doc => {
          copyfore.setAttribute('value', `${doc.link}`)
        })
        const copyback = document.getElementById('copyback')
        copyback.removeAttribute('value')
        Boards.collection('Grounds').doc({id: 'background'}).get().then(doc => {
          copyback.setAttribute('value', `${doc.link}`)
        })
        var pins = document.getElementsByName('rnd')
        for (const pawn of pins) {
          Boards.collection('Board').doc({id: `${pawn.id}`}).get().then(doc => {
          const string = JSON.stringify(doc.pin)
          const value = copy.getAttribute('value')
          if (pawn.classList.contains('pin1')) {
          copy.setAttribute('value', `${string}`) 
          } else {
          copy.setAttribute('value', `${value}, ${string}`)}    
        })}
        Boards.collection('Board').doc({id: 'pin13'}).get().then(doc => {
          const string = JSON.stringify(doc.pin)
          const value = copy.getAttribute('value')
          copy.setAttribute('value', `[${value}, ${string}]`)
        })}}

        function gearvis() {
          const gear = document.getElementById('gear')
          if (gear.classList.contains('invisible')) {
            gear.classList.remove('invisible')
          } else {gear.classList.add('invisible')}
        };

        function shut() {
          const cp = document.getElementById('cp')
          if (cp.classList.contains('flex')) {
          cp.classList.remove('flex');
          cp.classList.add('hidden');
        }};

        var elem = document.getElementById('everything')

        function openFullscreen() {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        };

        document.onkeyup = keyuphandler;
        function keyuphandler(ev){
          if (ev.key === 'e') {edit()}
          if (ev.key === 's') {doSave()}
          if (ev.key === 'c') {cp()}
          if (ev.key === 'i') {add()}
          if (ev.key === 't') {thieves()}
          if (ev.key === 'f') {openFullscreen()}
          if (ev.key === 'Escape') {shut()}
        }

  useEffect(() => {
    const query = userQuery(userInfo?.sub);

    client.fetch(query).then((data) => {
      setUser(data[0]);
    });
  }, [userInfo?.sub]);

  function redo() {localStorage.setItem('BoardedOnce', true);
  var screen = window.screen.width
  const foreground = document.getElementById('foreground')
  const pinid = 'pin13'
          const z = `${foreground.style.zIndex}`
          const dime = `${screen}`
          const pin = {pinid, z, dime}
          Boards.collection('Board').doc({id: 'pin13'}).update({pin});
  /*const ping = document.getElementsByName('rnd'); for (const pang of ping) {if (pang.style.width === '1500px') {pang.style.width = '100%'; pang.style.height = '100%'; const pinid = pang.getAttribute('id');
const pinclass = pang.getAttribute('class'); const pinstyle = pang.getAttribute('style'); const pin = {pinid, pinclass, pinstyle}; Boards.collection('Board').doc({id: `${pang.id}`}).update({pin});}};*/}

  const Boarded = localStorage.getItem('BoardedOnce')

  return (
    <div id="everything" className="flex justify-start items-center flex-col h-screen">
    {!Boarded && (
        <div className="absolute z-50 w-full bg-black bg-opacity-50 h-full flex justify-center items-center"><div className="relative text-xl flex flex-col space-y-4 justify-center items-center bg-purple-600 border-8 shadow-inner shadow-black border-black font-bold text-black px-3 p-2 h-4/12 w-7/12 md:h-5/12 md:w-5/12 rounded-lg"><p>Easily DRAG & DROP posts onto you board. If you don't know what the buttons do, make sure to check out the <a href="https://homebrewery.naturalcrit.com/share/n6u_v__tSCtr" target="_blank" rel="noreferrer" className="text-amber-600 underline-offset-1 underline">Manual</a>, which can also be found on the starter page. See that each button has a keybinding:)</p><p className="underline under-offset-1">*Please press F11 for fullscreen.*</p>
        <p className="underline under-offset-1">*Scroll to zoom is now available:)*</p>
        <p className="text-md cursor-pointer" onClick={(e) => {redo(); e.target.parentElement.parentElement.classList.add('hidden')}}>Click Here to Close This</p></div></div>
      )}
    <div id="board" className="fixed justify-center items-center left-0 z-10" >
      <Board/>
    </div>
    <div id="NewSidebar" className="right-0 h-screen fixed z-20">
        <NewSidebar user={user && user} />
      </div>
      <div id="otherused" className="right-0 bottom-0 fixed z-30">
        <Used/>
      </div>
    <div className="absolute flex-col justify-center items-center top-0 left-0 ldropdown z-30">
    <button className="ldropbtn gear" name="backdrop" draggable="false" onClick={gearvis} ><img src={Gear} id="gear" width="64" height="64" alt="Gear" draggable="false"/></button>
    <div className="ldropdowncontent">
      <button><Link to="/" name="backdrop" draggable="false" title="Go to Gallery"><img src={GalleryButton} width="64" height="67.2336" alt="Gallery" draggable="false"/></Link></button>
      <button to="#" onClick={cp} name="backdrop" draggable="false"><img src={Copper} width="64" height="65.2256" alt="Copper" draggable="false"/></button>
      <button to="#" onClick={doSave} name="backdrop" title="Save Board to Local Device" draggable="false"><img src={Save} width="64" height="65.2256" alt="Save" draggable="false"/></button>
      <button to="#" name="backdrop" draggable="false" title="Edit Mode Switch" onClick={edit}><img src={Compass} width="64" height="64" alt="Edit" draggable="false"/></button>
      <button to="#" onClick={add} name="backdrop" draggable="false" title="Inspiration Sidebar" ><img src={Inspiration} width="64" height="64" alt="Add" draggable="false"/></button>
      <button to="#" onClick={thieves} draggable="false" title="Hidden Messages"><img src={Eye} width="64" height="66.17872" alt="Eye" draggable="false"/></button>
      </div>
    </div>
    <div id="cp" className='hidden relative justify-center items-center z-40 bg-black bg-opacity-40 h-screen w-screen' >
      <Cp user={user && user}/>
    </div>
    {save && (
    <p className="absolute flex justify-center items-center bg-red-500 p-3 rounded-full text-2xl mt-20 z-40">
      Saved
    </p>
    )}
    {thieveson && (
    <img src={Eye} alt='eye' width="160" height="160" className="fixed my-40 animate-ping flex place-content-center rounded-full text-2xl z-40"/>
    )}
    </div>
      
  )
}

export default Generator