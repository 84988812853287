import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MasonryLayout2, Spinner } from '.'

import { client } from '../client'
import { userQuery,
    userSavedPinsQuery } from '../utils/data'

const Feed = () => {
    const [user, setUser] = useState();
    const [pins, setPins] = useState(null)
    const { userId } = useParams();

  useEffect(() => {
    const query = userQuery(userId);
    client.fetch(query).then((data) => {
      setUser(data[0]);
    });
  }, [userId]);

    useEffect(() => {
        const savedPinsQuery = userSavedPinsQuery(userId);

        client.fetch(savedPinsQuery).then((data) => {
            setPins(data);
        });
    }, [userId])


    if (!user) return <div className="mt-10"><Spinner/> <h1 className="text-yellow-300" >Must be Signed In and have Inspirations, or use another Account Id in the Url for its Inspirations.</h1> </div>;

    return (
        <div>
            <div>
          <MasonryLayout2 pins={pins} />
        </div>

        {pins?.length === 0 && (
          <div className="flex justify-center text-fuchsia-500 font-bold items-center w-full text-1xl mt-2">
            No Inspirations
          </div>
        )}
        </div>
    )
}

export default Feed
