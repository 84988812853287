import React, { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { VirtualSelect } from '../virtual-select.js'
import './Sidebar.css'

import { Spinner } from '.';
import { client } from '../client';
import { categories } from '../utils/data';

const CreatePin = ({ user }) => {
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [destination, setDestination] = useState('');
  const [social, setSocial] = useState('');
  const [loading, setLoading] = useState(false);
  const [tloading, setTloading] = useState(false);
  const [fields, setFields] = useState(false);
  const [category, setCategory] = useState('');
  const [content, setContent] = useState(false)
  const [permission, setPermission] = useState(false)
  const [imageAsset, setImageAsset] = useState(null);
  const [thievesAsset, setThievesAsset] = useState(null);
  const [wrongImageType, setWrongImageType] = useState(false);

  const navigate = useNavigate();

  const uploadImage = (e) => {
    if (user.subed === true) {
    const { type, name } = e.target.files[0];

    if (
      type === 'image/png' ||
      type === 'image/svg' ||
      type === 'image/jpeg' ||
      type === 'image/gif' 
    ) {
      setWrongImageType(false);
      setLoading(true);

      client.assets
        .upload('image', e.target.files[0], {
          contentType: type,
          filename: name,
        })
        .then((document) => {
          setImageAsset(document);
          setLoading(false);
        })
        .catch((error) => {
          console.log('Image upload error', error);
        });
    } else {
      setWrongImageType(true);
    }}
    if (user.subed === false) {
      const pp = document.getElementById('pp')
      pp.innerHTML = '<p class=" bg-black rounded-lg p-2 px-2 font-bold text-red-500">≖‿≖ SUCKERRR! If you got here by accident without paying, I am deeply sorry. If you knew what you were doing, I am not sorry. Anyway, Patreon is to the left, you need the perk to make posts:)</p>'
    }
  };

  const tuploadImage = (e) => {
    if (user.subed === true) {
    const { type, name } = e.target.files[0];

    if (
      type === 'image/png' ||
      type === 'image/svg' ||
      type === 'image/jpeg' ||
      type === 'image/gif' 
    ) {
      setWrongImageType(false);
      setTloading(true);

      client.assets
        .upload('image', e.target.files[0], {
          contentType: type,
          filename: name,
        })
        .then((document) => {
          setThievesAsset(document);
          setTloading(false);
        })
        .catch((error) => {
          console.log('Image upload error', error);
        });
    } else {
      setWrongImageType(true);
    }}
  };

  const rank = '5000'

  const savePin = () => {
    if(title && about && imageAsset?._id && thievesAsset?._id && category && content && permission) {
      const doc = {
          _type: 'pin',
          title,
          rank,
          about,
          destination,
          social,
          image: {
              _type: 'image',
              asset: {
                  _type: 'reference',
                  _ref: imageAsset?._id
              }
          },
          thieves: {
            _type: 'image',
            asset: {
                _type: 'reference',
                _ref: thievesAsset?._id
            }
        },
          userID: user._id,
          postedBy: {
              _type: 'postedBy',
              _ref: user._id
          },
          category,
      }; client.create(doc).then(() => {
        navigate('/')
    })
    } else {
    if(title && about && imageAsset?._id && category && content && permission) {
        const doc = {
            _type: 'pin',
            title,
            rank,
            about,
            destination,
            social,
            image: {
                _type: 'image',
                asset: {
                    _type: 'reference',
                    _ref: imageAsset?._id
                }
            },
            userID: user._id,
            postedBy: {
                _type: 'postedBy',
                _ref: user._id
            },
            category,
        }
        client.create(doc).then(() => {
            navigate('/')
        })
    } else {
        setFields(true);
        setTimeout(() => setFields(false), 2000)
    }}
  };



  VirtualSelect.init({
    ele: 'select',
    multiple: true,
    search: true,
    disableAllOptionsSelectedText: true,
    disableOptionGroupCheckbox: true,
    disableSelectAll: true,
    placeholder: 'Categories!',
    optionHeight: '30px | 40px',
    noOfDisplayValues: 22,
    moreText: 'more...', 
    noSearchResultsText: 'Investigation Roll Failed - Perhaps make it a Description/Keyword',
    showValueAsTags: true,
    dropboxWidth: '500px',
  });

  function putin(ev) {
     var dst = document.getElementById("one");
        dst.value = dst.value + ev.target.value;
        setCategory(dst.value)
    ;
};

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex lg:flex-row flex-col justify-center items-center w-full">
      <div className="flex flex-col w-8/12">
      <p name="backdrop"  className="bg-red-900 text-white p-2 rounded-lg mb-2">
      It is respectfully requested that you run your images through <a href="https://compressor.io/" rel='noreferrer' target="_blank" className="underline text-blue-300">Compressor.io</a> Lossy (if under 10MB), <a href="https://www.freeconvert.com/" rel='noreferrer' target="_blank" className="underline text-blue-300">FileConvert.com</a> (if above 10MB), or any other image compressor you prefer. The quality change at least on the given websites is unnoticeable, it will allow The Owlbear Post to have better loading times, and it will allow us to be more storage efficient. Not required, just appreciated.
      </p>
      <div className="flex lg:flex-row flex-col">
        <div className="flex flex-col w-full mr-2">
        <div className="bg-amber-800 p-3 rounded-lg flex flex-0.7 w-full cursor-pointer" name="backdrop">
          <div className="flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-420">
            {wrongImageType && <p>Wrong image type</p>}
            {!imageAsset ? (
              <label>
                <div className="flex flex-col cursor-pointer items-center justify-center h-full">
                  <div className="flex flex-col justify-center items-center">
                    <p className="font-bold text-2xl">
                      <AiOutlineCloudUpload />
                    </p>
                    <p className="text-lg">Click to upload</p>
                    <p className="text-lg">(Main Image)</p>
                  </div>
                  {loading && <Spinner />}
                  <p id="pp" className="flex mt-32 text-gray-300">
                    Read the Manual! Use a PNG, JPG, SVG, GIF less than 20MB. Note, JPG has no transparency. Average dimensions are around 2000x2800. Same rules apply on Hidden Messages.
                  </p>
                </div>
                <input
                  type="file"
                  name="upload-image"
                  onChange={uploadImage}
                  className="w-0 h-0"
                ></input>
              </label>
            ) : (
              <div className="relative h-full">
                <img
                  src={imageAsset?.url}
                  alt="uploaded-pic"
                  className="h-full w-full"
                />
                <button
                  type="button"
                  className="absolute bottom-3 right-3 p-3 rounded-full bg-white text-xl cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
                  onClick={() => setImageAsset(null)}
                >
                  <MdDelete />
                </button>
              </div>
            )}
          </div>
        </div>
            <div name="backdrop" className="bg-amber-800 p-2 mt-3 rounded-lg font-bold max-h-40 w-full overflow-y-scroll">
            <input type="checkbox" id="copyright" value={content}
            onChange={() => setContent(true)} />
            <label htmlFor="copyright" className="text-bold">  By checking this box, you pledge the following 4 sentences, and have read and understand the following 9 sentences. All of the filled contents in this page are appropriate for this website. The image(s) uploaded portray a Notice and/or its hidden messages, or other TTRPG relevant material that could within reason be on a Notice Board. None of the filled contents contain spam or overtly sexual material. All images and/or text in this submission were created by you, are in the public domain, have a creative commons license, and/or have been given permission to be used by you by their copyright holder. Use the comment section of this post for attribution if needed or desired. Users found to be in violation of the above pledge run the risk of their content being removed and their account banned depending on the degree of their violation. Low-quality/Low-effort posts may be removed, as we are trying to preserve a degree of quality on the website. Posts, comments, and creators can be reported to the website developer directly through his contact information in the website manual (the anonymous stamp in the sidebar).</label>
            </div>
        </div>
        <div className='flex flex-col lg:mt-0 mt-5 w-full ml-2'>
        <div className="bg-amber-800 p-3 rounded-lg flex flex-0.7 w-full cursor-pointer">
          <div className="flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-420">
            {wrongImageType && <p>Wrong image type</p>}
            {!thievesAsset ? (
              <label>
                <div className="flex flex-col cursor-pointer items-center justify-center h-full">
                  <div className="flex flex-col justify-center items-center">
                    <p className="font-bold text-2xl">
                      <AiOutlineCloudUpload />
                    </p>
                    <p className="text-lg">Click to upload</p>
                    <p className="text-lg">(Hidden Messages)</p>
                  </div>
                  {tloading && <Spinner />}
                  <p className="flex mt-32 text-gray-300">
                  Only visible when Hidden Message vision is active. Use the same dimensions as the first image for perfect fit. Use purple, pink, and/or red words/symbols. A soft black glow is useful.
                  </p>
                </div>
                <input
                  type="file"
                  name="upload-image"
                  onChange={tuploadImage}
                  className="w-0 h-0"
                ></input>
              </label>
            ) : (
              <div className="relative h-full">
                <img
                  src={thievesAsset?.url}
                  alt="uploaded-pic"
                  className="h-full w-full"
                />
                <button
                  type="button"
                  className="absolute bottom-3 right-3 p-3 rounded-full bg-white text-xl cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
                  onClick={() => setThievesAsset(null)}
                >
                  <MdDelete />
                </button>
              </div>
            )}
          </div>
        </div>
        <div name="backdrop" className="bg-amber-800 mt-3 p-2 rounded-lg font-bold w-full">
            <input type="checkbox" id="copyright" value={permission}
            onChange={() => setPermission(true)} />
            <label htmlFor="permission" className="text-bold">  By checking this box, you hereby grant users of The Owlbear Post permission to use the contents of this post in their TTRPG games and/or to share them Non-Commercially in their compiled Notice Boards.</label>
            </div>
            </div>
            </div>
            </div>

        <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full" name="backdrop">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength="50"
            placeholder="Title! (50 Characters Max)"
            className="outline-none text-2xl sm:text-2xl home shadow-md rounded-lg font-bold bg-amber-800 border-gray-500 p-1"
          />
          {user && (
            <div className="flex items-center rounded-lg">
              <img
                src={user.image}
                className="w-10 h-10 rounded-full p-1"
                alt="user-profile"
              />
              <p className="font-bold home text-rose-100">{user.userName}</p>
            </div>
          )}
          <input
            type="text"
            value={about}
            maxLength="50"
            onChange={(e) => setAbout(e.target.value)}
            placeholder="Search Keywords! (50 Characters Max)"
            className="outline-none text-base shadow-md rounded-lg sm:text-lg bg-amber-800 border-gray-500 p-1"
          />
          <input
            type="text"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            placeholder="Homebrew Link?"
            className="outline-none text-base shadow-md rounded-lg sm:text-lg bg-amber-800 border-gray-500 p-1"
          />
          <input
            type="text"
            value={social}
            onChange={(e) => setSocial(e.target.value)}
            placeholder="Linktree or Alternative?"
            className="outline-none text-base shadow-md rounded-lg sm:text-lg bg-amber-800 border-gray-500 p-1"
          />
          <div className="relative flex flex-col w-full">
            <div className="absolute w-full" id="cat" style={{ width: '300px' }}>
              <select multiple data-search="true"
                data-silent-initial-value-set="true"
              > 
              <optgroup label="Setting">
              {categories.slice(0, categories.length -63).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Urban">
                {categories.slice(12, categories.length -61).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Genre">
                {categories.slice(14, categories.length -53).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Theme">
                {categories.slice(22, categories.length -41).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Special">
                {categories.slice(36, categories.length -35).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Homebrew">
                {categories.slice(40, categories.length -29).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Challenge">
                {categories.slice(47, categories.length -22).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Originality">
                {categories.slice(54, categories.length -18).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Tabletop">
                {categories.slice(58, categories.length -13).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Reward">
                {categories.slice(62, categories.length -7).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
                <optgroup label="Enter a Competition">
                {categories.slice(70, categories.length -0).map((category) => (
                  <option
                    key={category.name}
                    value={category.name}
                  >
                    {category.name}
                  </option>
                ))}
                </optgroup>
              </select>
            </div>
            <input 
            type="text"
            id="two"
            onInput={putin}
            onPaste={putin}
            placeholder="Only after finished, Type: ,Ent"
            className="outline-none text-base mt-80 rounded-lg shadow-md sm:text-lg bg-amber-800 border-gray-500 p-1"
            />
            <div className="flex justify-end items-end mt-5">
                    <button type="button" onClick={savePin} className="bg-red-500 pin home text-white shadow-md font-bold p-2 rounded-full w-28 outline-none">
                        Publish Post
                    </button>
            </div>
          </div>
        </div>
      </div>
      {fields && (
        <p className="fixed p-3 rounded-lg mb-5 text-xl bg-pink-600 transition-all duration-1500 ease-in">
          Requires a Title, Description, Image, Category, the two checkboxes checked, and ",Ent" filled.
        </p>
      )}
    </div>
  );
};

export default CreatePin;
