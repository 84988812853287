import React, { useState, useEffect } from 'react';
import { MdDownloadForOffline } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from '.';
import { client, urlFor } from '../client';
import { pinDetailQuery } from '../utils/data';
import { AiTwotoneDelete } from 'react-icons/ai';
import Inspiration from '../assets/Inspiration.png'
import './Sidebar.css'

const PinDetail = ({ user }) => {
  const [pinDetail, setPinDetail] = useState(null);
  const [comment, setComment] = useState('');
  const [addingComment, setAddingComment] = useState(false);
  const [before, setBefore] = useState(true)
  const [uninspire, setUninspire] = useState(false);
  const [nat20, setNat20] = useState(true);
  const [nat1, setNat1] = useState(true);
 
  const { pinId } = useParams();

  const addComment = () => {
    if (comment) {
      setAddingComment(true);

      client
        .patch(pinId)
        .setIfMissing({ comments: [] })
        .insert('after', 'comments[-1]', [
          {
            comment,
            _key: uuidv4(),
            postedBy: {
              _type: 'postedBy',
              _ref: user._id,
            },
          },
        ])
        .commit()
        .then(() => {
          fetchPinDetails();
          setComment('');
          setAddingComment(false);
        });
    }
  };

  const deleteComment = (key) => {
    client.patch(pinId).unset([`comments[_key=="${key}"]`]).commit().then(() => {
      const comment = document.getElementById(`${key}`)
      comment.remove()
    });
  };

  const fetchPinDetails = () => {
    let query = pinDetailQuery(pinId);

    if (query) {
      client.fetch(query).then((data) => {
        setPinDetail(data[0]);
      });
    }
  };

  useEffect(() => {
    fetchPinDetails();
  }, [pinId]);

  function uninspiring() {
    if (!!pinDetail.save?.filter((item) => item.postedBy._id === user._id)?.length) {
      setUninspire(true)
    }
  }

  function unsave(e) {
    e.target.classList.add('pointer-events-none')
    e.target.innerHTML = "Unsaved"
    const key = pinDetail.save?.filter((item) => item.postedBy._id === user._id)[0]._key
    client.patch(pinId).unset([`save[_key=="${key}"]`]).commit().then(() => {
      setUninspire(false)
    });
  };
  

    function nat(ev) {
      setNat1(false)
      setNat20(false)
      const nat1 = document.getElementById('nat2');
      const nat20 = document.getElementById('nat21');
      const nat = pinDetail.save?.filter((item) => item.userId === `nat${user._id}`)
      const not = pinDetail.save?.filter((item) => item.userId === `zot${user._id}`)
      if (ev.target.id === 'nat20') {nat1.classList.remove('invisible')
        if (!!not?.length) {const key = not[0]._key;
        client.patch(pinId).unset([`save[_key=="${key}"]`]).commit()
        nat20.classList.remove('invisible')
      } else {
        client
        .patch(pinDetail._id)
        .setIfMissing({ save: [] })
        .insert('after', 'save[-1]', [
          {
            _key: uuidv4(),
            userId: `nat${user._id}`,
            postedBy: {
              _type: 'postedBy',
              _ref: `114129572048876628571`,
            },
          },
        ])
        .commit()
      }}
      if (ev.target.id === 'nat1') {nat20.classList.remove('invisible')
        if (!!nat?.length) {const key = nat[0]._key;
        client.patch(pinId).unset([`save[_key=="${key}"]`]).commit()
        nat1.classList.remove('invisible')
      } else {
        client
        .patch(pinDetail._id)
        .setIfMissing({ save: [] })
        .insert('after', 'save[-1]', [
          {
            _key: uuidv4(),
            userId: `zot${user._id}`,
            postedBy: {
              _type: 'postedBy',
              _ref: `100785891756078758396`,
            },
          },
        ])
        .commit()
        const zot = pinDetail.save?.filter((item) => item.postedBy._id === '100785891756078758396')?.length
        if (!pinDetail.save?.length) {var rank = 0} else {var rank = pinDetail.save?.length-zot*2}
        client.patch(pinId).set({rank: `${5000+rank-1}`}).commit()
      }}
    }

  if (!pinDetail) return <Spinner message="Loading Post..." />;

  function endbefore(ev) {
    setBefore(false);
    ev.target.classList.remove('absolute')
    ev.target.classList.remove('invisible');
    uninspiring();
  }

  return (
      <div
         className="flex lg:flex-row flex-col justify-center items-center w-full scale-75 lg:scale-100"
        style={{ maxWidth: '1000px', borderRadius: '10px' }}
      >
        <div className="relative flex justify-center items-center md:items-start flex-initial">
        {before && (
        <div className="w-508 bg-transparent">
          <Spinner message="Loading Post..." />
        </div>
        )}
          <img
            src={pinDetail?.image && urlFor(pinDetail.image).width(1500).url()}
            className="header invisible absolute justify-center min-w-500 max-w-lg"
            onLoad={endbefore}
            name="backdrop"
            alt="user-post"
          />
          {pinDetail?.thieves && (
          <img
            src={urlFor(pinDetail.thieves).url()}
            className="absolute top-0 bottom-0 justify-center invisible min-w-500 max-w-lg"
            name="thieves"
            alt="user-post"
          />
          )}
        </div>
        <div className="min-w-400 max-w-md p-5 flex-1">
            <h1 className="text-4xl font-bold break-words mt-3 home bg-amber-800 border-4 border-black shadow-inner shadow-black p-2 px-4 rounded-lg max-w-xs">
              {pinDetail.title}
            </h1>
          <div className="flex flex-row">
          <Link
            to={`/user-profile/${pinDetail.postedBy?._id}`}
            className="flex pin gap-2 mt-3 items-center"
          >
            <img
              className="w-10 h-10 rounded-full object-cover"
              src={pinDetail.postedBy?.image}
              alt="user-profile"
            />
            <p className="text-xl capitalize home text-rose-100">
              {pinDetail.postedBy?.userName}
            </p>
          </Link>
          <div className="flex mx-8 mt-3 pin items-center">
              <a
                href={`${pinDetail.image.asset.url}?dl=`}
                download
                onClick={(e) => e.stopPropagation()}
                className="flex items-center justify-center text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none"
              >
                <MdDownloadForOffline className=" bg-white w-10 h-10 rounded-full text-dark" />
                <div className="flex mx-1 home text-white">Post</div>
              </a>
            </div>
          </div>
          <br />
          <div className="flex items-center">
            {pinDetail.destination && (
            <a
              href={pinDetail.destination}
              title={pinDetail.destination}
              target="_black"
              rel="noreferrer"
              className="flex pin justify-between items-center bg-rose-800 text-white rounded-full p-2 text-bold home"
            >
              Homebrew
            </a>)}
            {pinDetail.social && (
            <a
              href={pinDetail.social}
              title={pinDetail.social}
              target="_black"
              rel="noreferrer"
              className="ml-4 flex pin justify-between items-center bg-rose-800 text-white rounded-full p-2 text-bold home"
            >
              Link Tree
            </a>)}
            {uninspire && (
            <button
              onClick={unsave}
              title="Removes it from your Inspirations"
              className="ml-4 flex pin justify-between items-center bg-rose-800 text-white rounded-full p-2 text-bold home"
            >
              Unsave Post
            </button>)}
          </div>
          {user && (
          <div title="Reload to Change" className="flex relative items-center mt-6 bg-black rounded-full w-fit shadow-lg">
          <div className="ml-1 font-bold flex justify-between items-center text-rose-100 rounded-l-full p-1 text-bold " >Performance:</div>
          <button
              id="nat21"
              className="invisible left-24 ml-3.5 absolute z-10 font-bold flex pin justify-between items-center pointer-events-none text-rose-100 bg-gray-800 p-1 text-bold"
            >
              Nat20 (+1<img src={Inspiration} alt="Die" width={20} height={20}/>)
            </button>
          {nat20 && !pinDetail.save?.filter((item) => item.userId === `nat${user._id}`)?.length ? (
          <button
              onClick={nat}
              id="nat20"
              title="+1 Inspiration"
              className="ml-1 font-bold flex pin justify-between items-center bg-gray-800 text-rose-100 p-1 text-bold "
            >
              Nat20 (+1<img src={Inspiration} alt="Die" width={20} height={20}/>)
            </button>
            ) : (
            <button
              className="ml-1 font-bold flex pin justify-between items-center pointer-events-none text-black bg-rose-800 p-1 text-bold"
            >
              Nat20 (+1<img src={Inspiration} alt="Die" width={20} height={20}/>)
            </button>
            )}
            <button
              id="nat2"
              className="invisible right-0 absolute z-10 ml-2 pl-2 pr-3 font-bold flex pin justify-between pointer-events-none text-rose-100 bg-gray-800 items-center rounded-r-full p-1 text-bold"
            >
              Nat1 (-1<img src={Inspiration} alt="Die" width={20} height={20}/>)
            </button>
            {nat1 && !pinDetail.save?.filter((item) => item.userId === `zot${user._id}`)?.length ? (
            <button
              onClick={nat}
              id="nat1"
              title="-1 Inspiration"
              className="ml-2 pl-2 pr-3 font-bold flex pin justify-between items-center text-rose-100 bg-gray-800 rounded-r-full p-1 text-bold "
            >
              Nat1 (-1<img src={Inspiration} alt="Die" width={20} height={20}/>)
            </button>
            ) : (
              <button
              className="ml-2 pl-2 pr-3 font-bold flex pin justify-between pointer-events-none text-black bg-rose-800 items-center rounded-r-full p-1 text-bold"
            >
              Nat1 (-1<img src={Inspiration} alt="Die" width={20} height={20}/>)
            </button>
            )}
          </div>
          )}
        
          <h2 className=" text-bold text-3xl pointer-events-none select-none underline underline-offset-4">__________________________</h2>
          <div className="max-h-370 overflow-y-scroll">
            {pinDetail.comments?.map((comment, i) => (
              <div
                className="flex gap-2 mt-4 items-center bg-amber-800 rounded-lg p-1"
                id={comment._key}
                key={i}
              >
              <Link to={`/user-profile/${comment.postedBy._id}`}>
                <img
                  src={comment.postedBy.image}
                  alt="user-profile"
                  className="w-10 h-10 rounded-full cursor-pointer"
                />
              </Link>
                <div className="flex flex-col">
                  <p className="home">{comment.postedBy.userName}</p>
                  <p className="break-words max-w-xs">{comment.comment}</p>
                </div>
                {comment.postedBy?._id === user?._id && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteComment(comment._key);
                  }}
                  className="bg-rose-700 p-1 opacity-60 hover:opacity-100 text-dark text-base rounded-full hover:shadow-md outlined-none pin"
                >
                  <AiTwotoneDelete />
                </button>
              )}
              </div>
            ))}
          </div>
          {user && (
          <div className="flex flex-wrap mt-6 gap-3">
              <img
                className="w-10 h-10 rounded-full"
                src={user.image}
                alt="user-profile"
              />
            <input
              className="flex-1 border-gray-100 outline-none border-2 p-2 rounded-2xl focus:border-gray-300"
              type="text"
              placeholder="Add a Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button
              type="button"
              className="bg-rose-700 text-white pin home rounded-full px-6 py-2 font-semihold text-base outline-none"
              onClick={addComment}
            >
              {addingComment ? 'Posting...' : 'Post'}
            </button>
          </div>
          )}
        </div>
      </div>
  );
};

export default PinDetail;
