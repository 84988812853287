import React, { useState } from 'react';
import {Rnd} from 'react-rnd'
import { useNavigate } from 'react-router-dom';
import Bulletin from '../assets/Bulletin.jpg';
import Capture from '../assets/Capture.JPG';
import {allowance, disallowance} from './allowance.js'
import Localbase from 'localbase'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import './Sidebar.css'

const Board = () => {

  const [back, setback] = useState(true)

  let Boards = new Localbase('Boards')

  const screen = window.screen.width

  const m = screen/1280
  
  function allowDrop(ev) {
    ev.preventDefault();
  };

  function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }
  const navigate = useNavigate();

  function doTheSave(p) {
    var pawn = document.getElementById(`${p}`);
    if (pawn) {
      const pinid = pawn.getAttribute('id');
      const pinclass = pawn.getAttribute('class');
      const pinstyle = pawn.getAttribute('style');
      if (pawn.children[2]) {
        const postid = pawn.children[2].getAttribute('id');
        const postclass = pawn.children[2].getAttribute('class');
        const picclass = pawn.children[2].children[0].getAttribute('class');
        const picid = pawn.children[2].children[0].getAttribute('id');
        const picsrc = pawn.children[2].children[0].getAttribute('src');
      if (!pawn.children[2].children[1]) {
        const pin = {pinid, pinclass, pinstyle, postid, postclass, picclass, picid, picsrc}
      Boards.collection('Board').doc({id: `${pawn.id}`}).update({pin});
      } else {
        const thiefclass = pawn.children[2].children[1].getAttribute('class');
        const thiefsrc = pawn.children[2].children[1].getAttribute('src');
        const pin = {pinid, pinclass, pinstyle, postid, postclass, picclass, picid, picsrc, thiefclass, thiefsrc}
      Boards.collection('Board').doc({id: `${pawn.id}`}).update({pin})};
      } else {
      const pin = {pinid, pinclass, pinstyle}
      Boards.collection('Board').doc({id: `${pawn.id}`}).update({pin})}};
      const foreground = document.getElementById('foreground');
      const pinid = 'pin13'
      const z = `${foreground.style.zIndex}`
      const dime = `${screen}`
      const pin = {pinid, z, dime}
      Boards.collection('Board').doc({id: 'pin13'}).update({pin});
  }

  window.onload = function() {
   var pins = document.getElementsByName('rnd')
   const background = document.getElementById('background');
   const foreground = document.getElementById('foreground');
   const boardeded = localStorage.getItem('Boarded')
   const boarded = localStorage.getItem('Board')
   if (navigator.userAgent.indexOf("Firefox") > 0) {
    if (boarded) {F()} else {if (boardeded) {F();localStorage.removeItem('Boarded')} else {B()}}
    }
   indexedDB.databases().then(r => {
    if (r[0]) {if (r[0].name === 'Boards') {F()} else { if (r[1]) {F()} else {B()}}} else {B()}});
   function B() {
    Boards.collection('Grounds').add({id: 'background', link: `${background.src}`});
    Boards.collection('Grounds').add({id: 'foreground', link: `${foreground.src}`});
    for (const pawn of pins) {
    const pinid = pawn.getAttribute('id');
    const pinclass = pawn.getAttribute('class');
    const pinstyle = pawn.getAttribute('style');
    if (pawn.children[2]) {
    const postid = pawn.children[2].getAttribute('id');
    const postclass = pawn.children[2].getAttribute('class');
    const picclass = pawn.children[2].children[0].getAttribute('class');
    const picid = pawn.children[2].children[0].getAttribute('id');
    const picsrc = pawn.children[2].children[0].getAttribute('src');
    if (!pawn.children[2].children[1]) {
    const pin = {pinid, pinclass, pinstyle, postid, postclass, picclass, picid, picsrc}
    Boards.collection('Board').add({id: `${pawn.id}`, pin: pin})} else {
    const thiefclass = pawn.children[2].children[1].getAttribute('class');
    const thiefsrc = pawn.children[2].children[1].getAttribute('src');
    const pin = {pinid, pinclass, pinstyle, postid, postclass, picclass, picid, picsrc, thiefclass, thiefsrc}
    Boards.collection('Board').add({id: `${pawn.id}`, pin: pin})};
    } else {
    const pin = {pinid, pinclass, pinstyle}
    Boards.collection('Board').add({id: `${pawn.id}`, pin: pin});}}
    const pinid = 'pin13'
    const z = `${foreground.style.zIndex}`
    const dime = `${screen}`
    const pin = {pinid, z, dime}
    Boards.collection('Board').add({id: 'pin13', pin: pin});
    localStorage.setItem('Board', true)
   }; function F() {
    Boards.collection('Grounds').doc({id: 'background'}).get().then(doc => {
      background.setAttribute("src", `${doc.link}`);
    })
    Boards.collection('Grounds').doc({id: 'foreground'}).get().then(doc => {
      foreground.setAttribute("src", `${doc.link}`)
    })
    Boards.collection('Board').doc({id: 'pin13'}).get().then(doc => {
      foreground.style.zIndex = doc.pin.z
      sessionStorage.setItem('dime', doc.pin.dime);
    })
   for (const pawn of pins) {
    Boards.collection('Board').doc({id: `${pawn.id}`}).get().then(doc => {
    pawn.setAttribute("class", `${doc.pin.pinclass}`)
    pawn.setAttribute("style", `${doc.pin.pinstyle}`)
    if (doc.pin.picid) {
    pawn.addEventListener("dragenter", allowance);
    let p = document.createElement('div')
    p.id=`${doc.pin.postid}`
    p.draggable="true"
    p.setAttribute('name', 'pin')
    p.setAttribute('target', '_blank')
    p.setAttribute('rel', 'noreferrer')
    p.className=`${doc.pin.postclass}`
    p.innerHTML = `<img class="${doc.pin.picclass}" alt="user-post" name="backdrop" id="${doc.pin.picid}" draggable="false" src="${doc.pin.picsrc}"/>`
    if (doc.pin.thiefclass) {
      p.innerHTML = 
    `<img class="${doc.pin.picclass}" alt="user-post" name="backdrop" id="${doc.pin.picid}" draggable="false" src="${doc.pin.picsrc}"/>
    <img class="${doc.pin.thiefclass}" alt="user-post" name="thieves" draggable="false" src="${doc.pin.thiefsrc}"/>`}
    pawn.appendChild(p)
    p.addEventListener("click", () => navigate(window.open(`/post/${doc.pin.picid}`)))
    p.addEventListener("dragstart", drag)
    p.addEventListener("dragend", disallowance)
    p.addEventListener("dragenter", allowance)
    pawn.classList.add('new')
    pawn.children[0].classList.add('new')
    const dime = sessionStorage.getItem('dime')
    const x = screen/dime
    if (x !== 1) {
    const trans = pawn.style.transform.replace('translate(', '').replaceAll('px', '').replace(')', '').split(',')
    pawn.style.top = `${pawn.style.top.replace('px', '') * x}px`;
    pawn.style.left = `${pawn.style.left.replace('px', '') * x}px`;
    pawn.style.width = `${pawn.style.width.replace('px', '') * x}px`;
    pawn.style.height = `${pawn.style.height.replace('px', '') * x}px`;
    pawn.style.transform = `translate(${trans[0] * x}px, ${trans[1] * x}px)`;
    doTheSave(pawn.id)
  }} else {
    const dime = sessionStorage.getItem('dime')
    const x = screen/dime
    pawn.style.width = `${pawn.style.width.replace('px', '') * x}px`;
    pawn.style.height = `${pawn.style.height.replace('px', '') * x}px`;
    doTheSave(pawn.id)
  }})}
  const editon = document.getElementById('editon')
  const pin1 = document.getElementById('pin1')
  if (pin1.classList.contains('invisible')) {
    editon.classList.remove('invisible')
  } else {editon.classList.add('invisible')}
}};

  function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    const foreground = document.getElementById('foreground')
    var z = foreground.style.zIndex
    const lank = document.getElementById(data)
    lank.classList.add("pointer-events-none");
    lank.classList.add("editable");
    lank.classList.remove("off");
    lank.classList.add(`${ev.target.id}`)
    var mx = ev.clientX
    var my = ev.clientY
    ev.target.style.left = `${mx-(100 * m)}px`
    ev.target.style.width = `${200 * m}px`
    ev.target.style.zIndex = +z+1
    foreground.style.zIndex = +z+2
    ev.target.appendChild(document.getElementById(data));
    ev.target.addEventListener("dragenter", allowance);
    const height = lank.offsetHeight
    ev.target.style.height = `${height}px`
    ev.target.style.top = my-`${height}`/2 + "px"
    const id = ev.target.id
    const truth = document.getElementsByName(`${id}`)
    for (const things of truth) {
      var by = things.className
      var bx = things.id
      ev.target.style.transform = `translate(${bx}px, ${by}px)`
      ev.target.style.left = mx-bx-(100 * m) + "px"
      ev.target.style.top = my-by-`${height}`/2 + "px"
      things.remove()
    }
    if (ev.target.children[0].classList.contains('new')) {
      ev.target.classList.add('new')
    }
    const scrollr = document.getElementById("scrollr")
    sessionStorage.setItem("scrollr", scrollr.scrollTop+1);
    const scrollBy = sessionStorage.getItem("scrollr")
    scrollr.scrollTo(0,scrollBy)
  };

  function stay(ev) {
    var mx = ev.clientX;
    var my = ev.clientY;
    const foreground = document.getElementById('foreground')
    var z = foreground.style.zIndex
    const trans = ev.target.style.transform
    const translate = trans.replace(/[^-\d. ]/g, '');
    const coord = translate.split(' ');
    var tx = coord[0];
    var ty = coord[1]
    const width = ev.target.style.width
    const w = width.replace(/[^-\d.]/g, '')
    const height = ev.target.style.height
    const h = height.replace(/[^-\d.]/g, '')
    if (ev.target.classList.contains('new')) {
      ev.target.classList.remove('new')
      ev.target.children[0].classList.remove('new')
      ev.target.style.top = my-h/2 + "px"
      ev.target.style.left = mx-w/2 + "px"
      ev.target.style.transform = 'translate(0px, 0px)'
    } else {
      var ex = mx-tx-w/2;
      var ey = my-ty-h/2;
      ev.target.style.top = ey + "px"
      ev.target.style.left = ex + "px"
    }
    ev.target.style.zIndex = +z+1
    foreground.style.zIndex = +z+2
  };

  function ex(ev) {
    var id = `pin${ev.target.id - 13}`
    const pin = document.getElementById(id)
    var lank = pin.children[2];
    const truth = document.getElementById('truth')
    lank.classList.remove("pointer-events-none");
    lank.classList.remove("editable");
    lank.classList.add("off");
      const trans = pin.style.transform
      const translate = trans.replace(/[^-\d. ]/g, '');
      const coord = translate.split(' ');
      let p = document.createElement('div')
      p.id=`${coord[0]}`
      p.setAttribute('name', `${id}`)
      p.className=`${coord[1]}`
      truth.appendChild(p)
      pin.style.width = `${1400 * m}px`
      pin.style.height = `${900 * m}px`
      pin.style.zIndex = "0"
      pin.style.top = "0px"
      pin.style.left = "0px"
      pin.style.transform = "translate(0px, 0px)"
      pin.removeEventListener("dragenter", allowance)
      lank.classList.remove(`${id}`)
      const drop = document.getElementById('drophere')
      drop.appendChild(lank);
  };

return (
  <TransformWrapper panning={{disabled: true}} wheel={{step: 0.1}}>
    <TransformComponent>
    <div id="bulletinboard">
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin1"
        lockAspectRatio={true}
        className="pin1 fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="14" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin2"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="15" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin3"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="16" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin4"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="17" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin5"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="18" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin6"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="19" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin7"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="20" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin8"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="21" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin9"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="22" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin10"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="23" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin11"
        lockAspectRatio={true}
        className="fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="24" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: `${1400 * m}`,
          height: `${900 * m}`,
        }}
        cancel=".cancel"
        id="pin12"
        lockAspectRatio={true}
        className="pin12 fixed bg-black cursor-move justify-center items-center bg-opacity-40 rounded-lg"
        name="rnd" onDrop={drop} onDragOver={allowDrop} onDragStart={stay}
      >
        <div className="fixed z-50 top-0 right-0"><button onClick={ex} id="25" className="home text-2xl bg-black bg-opacity-50 rounded-l-full rounded-b-full px-2 hover:bg-opacity-100 text-pink-600 pin">X</button></div>
      </Rnd>
      <div id="membrane" className="relative select-none h-screen w-screen block bg-transparent" draggable="false" onDragEnter={allowance}>
        <div className="-z-10 bg-black max-w-screen-2xl min-w-max -top-96 -left-96 pointer-events-none"/>
        <img 
          src={Bulletin}
          id="background"
          alt="Background Not Found"
          name="backdrop"
          onLoad={() => setback(false)}
          draggable="false"
          onChange={(e) => {console.log(e.style)}}
          className="w-full select-none h-full bg-center object-cover fixed z-10"
        />
        {back && (
          <img 
          src={Capture}
          alt="Background Not Found"
          name="backdrop"
          draggable="false"
          className="w-full h-full select-none bg-center object-cover z-50"
        />
        )}
      </div>
        <img 
          src="https://i.stack.imgur.com/Vkq2a.png"
          id="foreground"
          alt="Foreground Not Found"
          name="backdrop"
          draggable="false"
          style={{ zIndex: '31', top: '0', left: '0' }}
          className="w-screen select-none h-screen fixed bg-center block object-cover pointer-events-none"
        />
    </div>
    </TransformComponent>
    </TransformWrapper>
)}

export default Board