import React from 'react';
import Inspiration from '../assets/Inspiration.png'
import './Sidebar.css'

function Spinner({ message }) {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="animate-bounce">
      <img
        src={Inspiration}
        alt="Dice"
        className="animate-spin"
        height={50}
        width={75}
      />
      </div>
      <p className="text-lg text-center home text-white px-2">{message}</p>
    </div>
  );
}

export default Spinner;
