import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom';
import { CreatePin, Feed, Navbar, PinDetail, Search } from '../components';

const Pins = ({ user, setNote }) => {
  const [searchTerm, setSearchTerm] = useState('')

    return (
        <div className="px-2 md:px-5">
          <div>
            <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user} setNote={setNote}/>
          </div>  
          <div className="h-full">
            <Routes>
              <Route path="/" element={<Feed />}/>
              <Route path="/:categoryId" element={<Feed />}/>
              <Route path="/post/:pinId" element={<PinDetail user={user && user}/>}/>
              <Route path="/create-post" element={<CreatePin user={user && user}/>}/>
              <Route path="/search" element={<Search searchTerm={searchTerm} setSearch={setSearchTerm} />}/>
            </Routes>
          </div>
        </div>
    )
}

export default Pins
