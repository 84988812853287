import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AiTwotoneDelete } from 'react-icons/ai';
import Inspiration from '../assets/Inspiration.png'
import './Sidebar.css'
import LazyLoad from 'react-lazy-load';
import { client, urlFor } from '../client';

const Pin = ({ pin: { postedBy, image, thieves, _id, destination, save } }) => {
  const [postHovered, setPostHovered] = useState(false);
  const [before, setBefore] = useState(true);
  const [noAccount, setNoAccount] = useState(false);
  const [savingPost, setSavingPost] = useState(false);
  const [saved, setSaved] = useState(false);
  const [inspired, setInspired] = useState(false);

  const navigate = useNavigate();

  const user =
    localStorage.getItem('user') !== 'undefined'
      ? JSON.parse(localStorage.getItem('user'))
      : localStorage.clear();

  const alreadySaved = !!save?.filter(
    (item) => item?.postedBy?._id === user?.sub
  )?.length;

  const zot = save?.filter((item) => item?.postedBy?._id === '100785891756078758396')?.length

  if (!save?.length) {var rank = 0} else {var rank = save?.length-zot*2}

  const scroll = document.getElementById("scroll")

  const savePin = (id) => {
    if (!alreadySaved) {
      setSavingPost(true);
      setSaved(true);
      setInspired(true);
      setTimeout(() => setInspired(false), 1000)
      client
        .patch(id)
        .setIfMissing({ save: [] })
        .insert('after', 'save[-1]', [
          {
            _key: uuidv4(),
            userId: user?.sub,
            postedBy: {
              _type: 'postedBy',
              _ref: user?.sub,
            },
          },
        ])
        .commit()
        .then(() => {
          setSavingPost(false);
          client.patch(id).set({rank: `${5000+rank+1}`}).commit()
        });
    }
  };

  const deletePin = (id) => {
    client.delete(id).then(() => {
      window.location.reload();
    });
  };

  function dethief() {
    const bear = document.getElementById('bear')
    if (bear.classList.contains('grayscale')) {
        const screen = document.getElementsByName("backdrop");
    for (const backdrop of screen) {
      if (backdrop.classList.contains("grayscale")) {
        backdrop.classList.remove("grayscale")
      } else {backdrop.classList.add("grayscale")}
      if (backdrop.classList.contains("brightness-50")) {
        backdrop.classList.remove("brightness-50")
      } else {backdrop.classList.add("brightness-50")}
      if (backdrop.classList.contains("pointer-events-none")) {
        backdrop.classList.remove("pointer-events-none")
      } else {backdrop.classList.add("pointer-events-none")}
    };
    const cant = document.getElementsByName("thieves");
    for (const thieves of cant) {
      if (thieves.classList.contains("invisible")) {
        thieves.classList.remove("invisible")
      } else {thieves.classList.add("invisible")}
      };
    const amber = document.getElementById("amber");
      if (amber.classList.contains("wood")) {
        amber.classList.remove("wood")
      } else {amber.classList.add("wood")}
      if (amber.classList.contains("graywood")) {
        amber.classList.remove("graywood")
      } else {amber.classList.add("graywood")}};
    navigate(`/post/${_id}`);
  };

    function backdropon(ev) {
      const img = document.getElementById(`${_id}`)
      if (img.classList.contains('invisible')) {
      img.classList.add('header')
      img.classList.remove('invisible')};
      ev.target.classList.remove('absolute');
      const bear = document.getElementById('bear')
      if (bear.classList.contains('grayscale')) {
        ev.target.classList.add('grayscale')
        ev.target.classList.add('brightness-50')
      };
      setBefore(false);
    };

    function thiefon(ev) {
      const bear = document.getElementById('bear')
      if (bear.classList.contains('grayscale')) {
        ev.target.classList.remove('invisible')
      }
    };

    function touched() {
      setPostHovered(true)
      setTimeout(() => setPostHovered(false), 6000)
    }

      function loadin() {
        const scrollBy = sessionStorage.getItem("scroll") 
        const pinid = sessionStorage.getItem("pinid") 
        if (pinid === `${_id}`) {
        scroll.scrollTo(0,scrollBy)
        sessionStorage.setItem("scroll", 0) 
        }}

  return (
    <div id={_id} className="m-5 pin invisible" onClick={() => {sessionStorage.setItem("scroll", scroll.scrollTop); sessionStorage.setItem("pinid", _id)}}>
      <div
        onMouseEnter={() => setPostHovered(true)}
        onMouseLeave={() => setPostHovered(false)}
        onTouchStart={touched}
        onClick={dethief}
        className="relative justify-start items-center cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
      >
        {before && (
        <div className="bg-amber-900 bg-opacity-50 h-370"/>
        )}
        <LazyLoad offsetVertical={1000}>
        <div>
        <img
          className="absolute w-full justify-center"
          alt="user-post"
          name="backdrop"
          draggable="false"
          onLoad={backdropon}
          src={urlFor(image).width(500).url()}
        />
        {thieves && (
        <img
          className="absolute top-0 bottom-0 justify-center invisible w-full"
          alt="user-post"
          name="thieves"
          onLoad={thiefon}
          draggable="false"
          src={urlFor(thieves).width(500).url()}
        />)}
        </div>
        </LazyLoad>
        {postHovered && (
          <div
            className="absolute bottom-0 w-full h-full flex items-center flex-col justify-between p-0.5 pb-2"
            style={{ height: '100%' }}
          >
            <div className="flex justify-between items-center gap-2 w-full">
              {destination && (
                <a
                  href={destination}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {e.stopPropagation();}}
                  title={destination.length > 22
                    ? `${destination.slice(8, 30)}...`
                    : destination}
                  className="bg-rose-500 home flex items-center gap-2 text-black font-bold text-xs p-2 rounded-full opacity-60 hover:opacity-100 hover:shadow-md pin"
                >
                  HB
                </a>
              )}
              {postedBy?._id === user?.sub && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    deletePin(_id);
                  }}
                  className="bg-rose-500 p-2 opacity-60 hover:opacity-100 text-dark font-bold text-base rounded-full hover:shadow-md outlined-none pin"
                >
                  <AiTwotoneDelete />
                </button>
              )}
            </div>
            {noAccount && (
              <div className="flex items-center justify-between ">
                <h1 className="bg-black text-white text-lg p-2 rounded-full">Requires Account</h1>
              </div>
            )}
            {inspired && (
            <img src={Inspiration} alt="Die" width={64} height={64} className="animate-ping"/>
            )}
            {savingPost && (
              <div className="absolute flex items-center justify-between bottom-2 z-30">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="bg-pink-500 flex home text-white bottom-0 font-bold px-8 py-1 text-xl rounded-3xl hover:shadow-md outlined-none"
                >
                  Saving  Post . . .
                </button>
              </div>
            )}
            {saved && (
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="bg-purple-500 flex home text-yellow-500 bottom-0 font-bold px-2 py-1 text-xl rounded-3xl hover:shadow-md outlined-none"
                >
                  {rank+1} Inspiration <img src={Inspiration} alt="Die" width={28} height={28}/>
                </button>
              </div>
            )}
            {!saved && (
            <div className="flex items-center justify-between z-10">
              {alreadySaved ? (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="bg-purple-500 flex home text-yellow-500 bottom-0 font-bold px-2 py-1 text-xl rounded-3xl hover:shadow-md outlined-none"
                >
                  {rank} Inspiration <img src={Inspiration} alt="Die" width={28} height={28}/>
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (user) {
                    savePin(_id)
                    } else {
                      setNoAccount(true);
                      setTimeout(() => setNoAccount(false), 2000)
                    };
                  }}
                  type="button"
                  className="bg-rose-700 inspiration flex home text-rose-200 bottom-0 font-bold px-2 py-1 text-xl rounded-3xl hover:shadow-md outlined-none"
                >
                  {rank} Inspiration <img src={Inspiration} alt="Die" width={28} height={28}/>
                </button>
              )}
            </div>
            )}
          </div>
        )}
      </div>
      <Link
        to={`/user-profile/${postedBy?._id}`}
        onLoad={loadin}
        className="flex gap-2 mt-2 items-center justify-center w-3/4 break-words pin"
      >
        <img
          className="w-8 h-8 rounded-full object-cover"
          src={postedBy?.image}
          name={postedBy?.userName}
          alt="?"
        />
        <p className="font-semihold capitalize home text-rose-100">{postedBy?.userName}</p>
      </Link>
      <br />
    </div>
  );
};

export default Pin;
