import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MasonryLayout, Spinner } from '.'

import { client } from '../client'
import { feedQuery, searchQuery } from '../utils/data'
import './Sidebar.css'

const Feed = () => {
    const [loading, setLoading] = useState(false)
    const [pins, setPins] = useState(null)
    const { categoryId } = useParams();

    useEffect(() => {
        setLoading(true);

        if(categoryId) {
            const query = searchQuery(categoryId)

            client.fetch(query).then((data) => {
                setPins(data);
                setLoading(false);
            })
        } else {
            client.fetch(feedQuery).then((data) => {
                setPins(data);
                setLoading(false);
            })
        }

    }, [categoryId])

    if(loading) return <div className="mt-12"><Spinner message="Rolling Investigation!" /></div>
    
    if(!pins?.length) return <h2 className="flex items-center justify-center text-white home">Failed Investigation Check</h2>

    return (
        <div>
            {pins && <MasonryLayout pins={pins} />}
        </div>
    )
}

export default Feed
