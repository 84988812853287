import React from 'react';
import Bulletin from '../assets/Bulletin.jpg'
import 'react-rnd'

const Redirect = () => {  

  return (
    <div id="everything" className="flex justify-start items-center flex-col h-screen w-screen">
        <img 
          src={Bulletin}
          id="background"
          alt="Background Not Found"
          name="backdrop"
          draggable="false"
          className="w-full h-full bg-center object-cover fixed"
        />
        <p className="z-10 text-6xl home text-amber-400 text-center mt-52">Sorry, Under Maintenance</p>
        <p className="z-10 text-4xl home text-amber-400 text-center mt-2">Come back in 10 Minutes:)</p>
    </div>
  )
}

export default Redirect