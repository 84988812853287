import React, { useState, useEffect } from 'react';
import { HiMenu } from 'react-icons/hi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Link, Route, Routes } from 'react-router-dom';
import { Sidebar, UserProfile, Sidebarphone, StarterPage } from '../components';
import { userQuery } from '../utils/data';
import { client } from '../client';
import Pins from './Pins';
import '../components/Sidebar.css';
import wood from '../assets/Bulletin2.jpg'


const Home = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [user, setUser] = useState();
  const [note, setNote] = useState(false)

  function thieves() {
    const screen = document.getElementsByName("backdrop");
    for (const backdrop of screen) {
      if (backdrop.classList.contains("grayscale")) {
        backdrop.classList.remove("grayscale")
      } else {backdrop.classList.add("grayscale")}
      if (backdrop.classList.contains("brightness-50")) {
        backdrop.classList.remove("brightness-50")
      } else {backdrop.classList.add("brightness-50")}
      if (backdrop.classList.contains("pointer-events-none")) {
        backdrop.classList.remove("pointer-events-none")
      } else {backdrop.classList.add("pointer-events-none")}
    };
    const cant = document.getElementsByName("thieves");
    for (const thieves of cant) {
      if (thieves.classList.contains("invisible")) {
        thieves.classList.remove("invisible")
      } else {thieves.classList.add("invisible")}
      };
    const bamber = document.getElementById("amber");
      if (bamber.classList.contains("wood")) {
        bamber.classList.remove("wood")
      } else {bamber.classList.add("wood")}
      if (bamber.classList.contains("graywood")) {
        bamber.classList.remove("graywood")
      } else {bamber.classList.add("graywood")};
  };

  window.onresize = () => {
        sessionStorage.setItem("scroll", 0);
        window.location.reload();
      };

  const userInfo =
    localStorage.getItem('user') !== 'undefined'
      ? JSON.parse(localStorage.getItem('user'))
      : localStorage.clear();

  useEffect(() => {
    const query = userQuery(userInfo?.sub);

    client.fetch(query).then((data) => {
      setUser(data[0]);
    });
  }, [userInfo?.sub]);

  function togglesidebar() {
    setToggleSidebar(true);
    const bear = document.getElementById('bear')
    if (bear.classList.contains('grayscale')) {
      thieves();
      const menu = document.getElementById('himenu');
      if (menu.classList.contains('bg-pink-500')) {
        menu.classList.remove('bg-pink-500')
      } else {menu.classList.add('bg-pink-500')}
      if (menu.classList.contains('rounded-full')) {
        menu.classList.remove('rounded-full')
      } else {menu.classList.add('rounded-full');}
    };
  }

  const seen = localStorage.getItem('seen')

  return (
    <div className="flex md:flex-row flex-col h-screen transition-height duration-75 ease-out">
      {!seen && user && (
        <div className="absolute z-50 w-full bg-black bg-opacity-50 h-full flex justify-center items-center"><div className="relative text-xl flex flex-col space-y-4 justify-center items-center bg-purple-600 border-8 shadow-inner shadow-black border-black font-bold text-black px-3 p-2 h-4/12 w-9/12 md:h-5/12 md:w-5/12 rounded-lg"><p>The Owlbear Post works best on PCs in Chrome (the Board is available for screens larger than tablets). Go to the <a href="https://discord.gg/YDv2NMpDRr" target="_blank" rel="noreferrer" className="text-blue-800 underline underline-offset-1">Discord</a> "help" channel to report any problems.</p>
        <p className="text-md cursor-pointer" onClick={(e) => {localStorage.setItem("seen", true); sessionStorage.setItem("seen", true); e.target.parentElement.parentElement.classList.add('hidden')}}>Click Here to Close This</p></div></div>
      )}
      {note && (
        <div className="fixed z-50 w-screen bg-black bg-opacity-50 h-screen flex justify-center items-center"><div className="relative text-xl flex flex-col space-y-4 justify-center items-center bg-purple-600 border-8 shadow-inner shadow-black border-black font-bold text-black px-3 p-2 h-4/12 w-7/12 md:h-5/12 md:w-5/12 rounded-lg"><p>If you wish to add to the gallery either create a <a href="https://discord.gg/YDv2NMpDRr" target="_blank" rel="noreferrer" className="text-blue-800 underline underline-offset-1">Discord Submission</a> or join any <a href='https://www.patreon.com/umbraaevaz' className="text-amber-600 underline underline-offset-1" target="_blank" rel="noreferrer">Patreon</a> tier.</p>
        <p className="text-md cursor-pointer" onClick={(e) => setNote(false)}>Click Here to Close This</p></div></div>
      )}
      <img src={wood} alt="wood" name="backdrop" className="fixed flex w-full -z-10"/>
      <div className="hidden md:flex h-screen flex-initial">
        <Sidebar user={user && user} />
      </div>
      <div className="flex md:hidden flex-row">
        <div className="p-2 w-full flex flex-row justify-between items-center shadow-lg">
          <HiMenu
            fontSize={40}
            id="himenu"
            className="cursor-pointer"
            onClick={() => togglesidebar()}
          />
          {user && (
          <Link to={`/user-profile/${user?._id}`}>
            <img
              src={user?.image}
              alt="user-pic"
              className="w-9 h-9 rounded-full"
            />
          </Link>
          )}
        </div>
        {toggleSidebar && (
          <div className="fixed w-350 bg-black h-screen overflow-y-auto shadow-lg z-30 animate-slide-in">
            <div className="absolute w-full flex justify-end items-center p-2 z-10">
              <AiFillCloseCircle
                fontSize={30}
                className="rounded-full bg-white cursor-pointer"
                onClick={() => setToggleSidebar(false)}
              />
            </div>
            <Sidebarphone closeToggle={setToggleSidebar} user={user && user} />
          </div>
        )}
      </div>
      <div id="scroll" className="home-scrolled pb-2 flex-1 h-screen overflow-y-scroll">
        <div id="amber" className="wood flex flex-col">
        <Routes>
          <Route path="/user-profile/:userId" element={<UserProfile />} />
          <Route path="/starter-page" element={<StarterPage />} />
          <Route path="/*" element={<Pins user={user && user} setNote={setNote} id="pins" />} />
        </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
