import React from 'react';
import { useRef, useEffect, useState } from "react";
import useScript from "./usescript";
import jwt_decode from 'jwt-decode';

import { client } from '../client'
import { useNavigate } from 'react-router-dom';
  
import './Sidebar.css'
import GoogleLogin from 'react-google-login';

/*
const Login = () => {
    const googleSignInButton = useRef(null);

  const navigate = useNavigate();
  
  const user =
    localStorage.getItem('user') !== 'undefined'
      ? JSON.parse(localStorage.getItem('user'))
      : localStorage.clear();

      const onGoogleSignIn = (response) => {
        const responsePayload = jwt_decode(response.credential);
        localStorage.setItem('user', JSON.stringify(responsePayload));
    
        const { name, sub, picture, email } = responsePayload;
    
        const doc = {
          _id: sub,
          _type: 'user',
          userName: name,
          email: email,
          image: picture,
          subed: false,
        }
      
      client.createIfNotExists(doc)
        .then(() => {
          navigate('/starter-page', { replace: true});
          window.location.reload(); 
        })
      } 
  return (
    <div>
      <h1>Login with Google</h1>
      <GoogleLogin
        type="dark"
        clientId="967342586692-32p7qo57iv3ud1v6nosc2tc01vigoerj.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={onGoogleSignIn}
        onFailure={onGoogleSignIn}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
};

export default Login; */


const Login = () => {
    const navigate = useNavigate();
    const user =
    localStorage.getItem('user') !== 'undefined'
      ? JSON.parse(localStorage.getItem('user'))
      : localStorage.clear();
    const onGoogleSignIn = (response) => {
    const responsePayload = jwt_decode(response.credential);
    localStorage.setItem('user', JSON.stringify(responsePayload));

    const { name, sub, picture, email } = responsePayload;

    const doc = {
      _id: sub,
      _type: 'user',
      userName: name,
      email: email,
      image: picture,
      subed: false,
    }
  
  client.createIfNotExists(doc)
    .then(() => {
      navigate('/starter-page', { replace: true});
      window.location.reload(); 
    })
  } 
    const googleSignInButton = useRef(null);
    const [apiLoaded, setApiLoaded] = useState(false);
  
    useScript("https://accounts.google.com/gsi/client", () => {
      window.google.accounts.id.initialize({
        client_id: '967342586692-32p7qo57iv3ud1v6nosc2tc01vigoerj.apps.googleusercontent.com',
        callback: onGoogleSignIn,
      });
      setApiLoaded(true)
    });

    useEffect(() => {
      if (apiLoaded) {
        window.google.accounts.id.renderButton(
          googleSignInButton.current,
          { theme: 'filled_black', size: 'large', shape: 'circle', width: 200,}
        );
        if (!user) {setTimeout( function() {window.google.accounts.id.prompt()}, 18000)};
      }
    }, [apiLoaded, user]);
  /*  useEffect(() => {
      window.google.accounts.id.initialize({
        client_id: '967342586692-32p7qo57iv3ud1v6nosc2tc01vigoerj.apps.googleusercontent.com',
        callback: onGoogleSignIn,
      });
      setApiLoaded(true)
    }, [setApiLoaded, onGoogleSignIn]);

    useEffect(() => {
      if (apiLoaded) {
        window.google.accounts.id.renderButton(
          googleSignInButton.current,
          { theme: 'filled_black', size: 'large', shape: 'circle', width: 200,}
        );
        if (!user) {setTimeout( function() {window.google.accounts.id.prompt()}, 18000)};
      }
    }, [apiLoaded, user]);*/
    return <div className="test" ref={googleSignInButton}></div>;
  }

  export default Login

